import { Injectable } from '@angular/core'
import { CreatePlanResultFileResult, CreatePlanResultsParams } from '../../../generated/efp-api'

@Injectable()
export abstract class PlanResultDao {
  protected constructor() {}

  abstract createPlanResult(
    planId: number,
    body: CreatePlanResultsParams
  ): Promise<CreatePlanResultFileResult[]>
}
