import { Injectable } from '@angular/core'
import { PlanType } from 'formwork-planner-lib'
import { Plan } from '../models/plan'
import { Translation } from './translation.service'
import { ZoomAndPanService } from './zoom-and-pan.service'
import {
  PlanCreateCommandParams,
  PlanType as ApiPlanType,
  NavStep,
  PlansModel,
} from '../../generated/efp-api'
import { Project } from '../models/project'
import { PlanCreationRepository } from '../repositories/plan-creation.repository'
import { PlanRepository } from '../repositories/plan.repository'
import { ProjectRepository } from '../repositories/project.repository'
import { Router } from '@angular/router'
import { WidgetService } from './widget.service'

@Injectable({
  providedIn: 'root',
})
export class PlanService {
  constructor(
    private readonly translation: Translation,
    private readonly router: Router,
    private readonly planRepository: PlanRepository,
    private readonly zoomAndPanService: ZoomAndPanService,
    private readonly projectRepository: ProjectRepository,
    private readonly planCreationRepository: PlanCreationRepository,
    private readonly widgetService: WidgetService
  ) {}

  public static mapPlanModelToPlan(model: PlansModel): Plan {
    return {
      id: model.id,
      name: model.name,
      date: new Date(model.date),
      lastUsed: new Date(model.lastUsed),
      buildingType: model.buildingType === 'SLAB' ? PlanType.SLAB : PlanType.WALL,
      projectId: model.projectId,
      settingsId: model.settingsId,
      currentStep: model.currentStep,
      stockId: model.stockId ?? null,
      serializedMesh: model.serializedMesh ?? undefined,
      isDoka360: model.isDoka360 ?? undefined,
      isCompleted: model.isCompleted ?? undefined,
    }
  }

  public async findOne(planId: number): Promise<Plan> {
    const plan = await this.planRepository.findOne(planId)
    if (!plan) {
      throw new Error('Plan not found in loadPlan')
    }
    return plan
  }

  public async createPlan(
    planType: string,
    projectId?: number,
    planName?: string,
    shouldOpenInNewTab = false
  ): Promise<void> {
    let newPlanName = planName
    if (!newPlanName) {
      const translationKey =
        planType === PlanType.WALL ? 'HOME.QUICK_WALL_PREFIX' : 'HOME.QUICK_CEILING_PREFIX'
      newPlanName = this.translation.translate(translationKey)
    }

    const createPlanDto: PlanCreateCommandParams = {
      name: newPlanName,
      addNextPlanNumberToName: planName ? false : true,
      buildingType: planType === PlanType.WALL ? ApiPlanType.Wall : ApiPlanType.Slab,
      projectId,
      currentStep: NavStep.Plansettings,
    }

    let project: Project | undefined
    if (createPlanDto.projectId !== undefined && createPlanDto.projectId !== null) {
      project = await this.projectRepository.findOne(createPlanDto.projectId, false)
    }

    const plan = await this.planCreationRepository.createPlan(createPlanDto, project)

    if (shouldOpenInNewTab) {
      const urlPath = this.router.serializeUrl(
        this.router.createUrlTree(['plansettings/' + plan.id])
      )
      this.widgetService.navigateInNewWebTab(urlPath)
    } else {
      this.beforeOpeningPlan()
      await this.router.navigate(['plansettings/' + plan.id], {
        queryParams: { backUrl: '/homepage' },
      })
    }
  }

  private beforeOpeningPlan(): void {
    this.zoomAndPanService.reset()
  }
}
