import { NgModule } from '@angular/core'
import { AppSettingDao } from './app-settings.dao'
import { ArticleDao } from './article.dao'
import { ChangedResultPartDao } from './changed-result-part.dao'
import { CycleDao } from './cycle.dao'
import { FavouriteDao } from './favourite.dao'
import { AppSettingHttpDao } from './http/app-settings.http-dao'
import { ArticleHttpDao } from './http/article.http-dao'
import { ChangedResultPartHttpDao } from './http/changed-result-part.http-dao'
import { CycleHttpDao } from './http/cycle.http-dao'
import { FavouriteHttpDao } from './http/favourite.http-dao'
import { OnboardingHttpDao } from './http/onboarding.http-dao'
import { PlanAccessoryLineHttpDao } from './http/plan-accessory-line.http-dao'
import { PlanCreationHttpDao } from './http/plan-creation.http-dao'
import { PlanOutlineHttpDao } from './http/plan-outline.http-dao'
import { CalculationResultHttpDao } from './http/calculation-result.http-dao'
import { PlanSettingsHttpDao } from './http/plan-settings.http-dao'
import { PlanVisibilitySettingsHttpDao } from './http/plan-visibility-settings.http-dao'
import { PlanHttpDao } from './http/plan.http-dao'
import { ProjectHttpDao } from './http/project.http-dao'
import { SalesContactHttpDao } from './http/sales-contact.http-dao'
import { ScreenshotHttpDao } from './http/screenshot.http-dao'
import { StockHttpDao } from './http/stock.http-dao'
import { UserHttpDao } from './http/user.http-dao'
import { OnboardingDao } from './onboarding.dao'
import { PlanAccessoryLineDao } from './plan-accessory-line.dao'
import { PlanCreationDao } from './plan-creation.dao'
import { PlanOutlineDao } from './plan-outline.dao'
import { CalculationResultDao } from './calculation-result.dao'
import { PlanSettingsDao } from './plan-settings.dao'
import { PlanVisibilitySettingsDao } from './plan-visibility-settings.dao'
import { PlanDao } from './plan.dao'
import { ProjectDao } from './project.dao'
import { SalesContactDao } from './sales-contact.dao'
import { ScreenshotDao } from './screenshot.dao'
import { StockDao } from './stock.dao'
import { UserDao } from './user.dao'
import { CalculationResultResetCalculationDao } from './reset-calculation-result.dao'
import { ResetCalculationResultHttpDao } from './http/reset-calculation-result.http-dao'
import { BlacklistArticleDao } from './blacklist-article.dao'
import { BlacklistArticleHttpDao } from './http/blacklist-article.http-dao'
import { AdvertisementDao } from './advertisement.dao'
import { PlanResultDao } from './plan-result.dao'
import { PlanResultHttpDao } from './http/plan-result.http-dao'

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    {
      provide: AppSettingDao,
      useClass: AppSettingHttpDao,
    },
    {
      provide: UserDao,
      useClass: UserHttpDao,
    },
    {
      provide: FavouriteDao,
      useClass: FavouriteHttpDao,
    },
    {
      provide: PlanSettingsDao,
      useClass: PlanSettingsHttpDao,
    },
    {
      provide: PlanVisibilitySettingsDao,
      useClass: PlanVisibilitySettingsHttpDao,
    },
    {
      provide: PlanDao,
      useClass: PlanHttpDao,
    },
    {
      provide: PlanCreationDao,
      useClass: PlanCreationHttpDao,
    },
    {
      provide: ProjectDao,
      useClass: ProjectHttpDao,
    },
    {
      provide: ArticleDao,
      useClass: ArticleHttpDao,
    },
    {
      provide: StockDao,
      useClass: StockHttpDao,
    },
    {
      provide: CycleDao,
      useClass: CycleHttpDao,
    },
    {
      provide: PlanOutlineDao,
      useClass: PlanOutlineHttpDao,
    },
    {
      provide: PlanAccessoryLineDao,
      useClass: PlanAccessoryLineHttpDao,
    },
    {
      provide: ChangedResultPartDao,
      useClass: ChangedResultPartHttpDao,
    },
    {
      provide: SalesContactDao,
      useClass: SalesContactHttpDao,
    },
    {
      provide: ScreenshotDao,
      useClass: ScreenshotHttpDao,
    },
    {
      provide: CalculationResultDao,
      useClass: CalculationResultHttpDao,
    },
    {
      provide: CalculationResultResetCalculationDao,
      useClass: ResetCalculationResultHttpDao,
    },
    {
      provide: OnboardingDao,
      useClass: OnboardingHttpDao,
    },
    {
      provide: BlacklistArticleDao,
      useClass: BlacklistArticleHttpDao,
    },
    AdvertisementDao,
    {
      provide: PlanResultDao,
      useClass: PlanResultHttpDao,
    },
  ],
})
export class DaoModule {}
