import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { Capacitor } from '@capacitor/core'
import { Location } from '@angular/common'
import { NavigationExtras, Router } from '@angular/router'
import { map } from 'rxjs'
import { PlanRepository } from '../../../repositories/plan.repository'

@Component({
  selector: 'efp-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnInit {
  @Input() title!: string
  @Input() isNavStep = false // required because navSteps use a different icon
  @Input() navLink?: string
  @Input() hasNavigation = true
  @Input() planId?: number
  @Input() backNavOptions?: NavigationExtras
  @Input() navDisabledAndGray = false
  @Input() hasFeedback = true
  @Input() isModal = false
  @Input() handleNavigationClick = true
  //menu disabled in agb & privacy because of ionic router, delete condition with #81044
  @Input() disablePrimaryMenu?: boolean
  @Input() webCollapsableEnabled?: boolean
  @Input() showTitleOnWeb?: boolean
  @Input() isHomeScreen = false
  @Input() showSyncIndicator = true // #94020 Appsettings call in RequestSyncIndicatorComponent throws an error, disable it on login

  @Output() readonly navigateClicked = new EventEmitter<void>()
  public showContextMenuButton = true

  constructor(
    private readonly modalController: ModalController,
    private readonly location: Location,
    private readonly router: Router,
    private readonly planRepository: PlanRepository
  ) {}

  ngOnInit(): void {
    this.showContextMenuButton = this.hasFeedback && this.isNative()
  }

  public isNative(): boolean {
    return Capacitor.isNativePlatform()
  }

  public isReadOnly$ = this.planRepository.plans$.pipe(
    map((plans) => plans.find((plan) => plan.id === this.planId)?.isCompleted)
  )

  async navigate(): Promise<void> {
    this.navigateClicked.emit()
    if (!this.handleNavigationClick) {
      return
    }

    if (this.navLink) {
      await this.router.navigate([this.navLink], this.backNavOptions)
    } else {
      this.location.back()
    }
  }

  dismiss(): void {
    void this.modalController.dismiss()
  }
}
