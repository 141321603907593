import { inject, Injectable } from '@angular/core'
import { PlanResultDao } from '../plan-result.dao'
import {
  CreatePlanResultFileResult,
  CreatePlanResultsParams,
  PlanResultApiClient,
} from '../../../../generated/efp-api'
import { firstValueFrom } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class PlanResultHttpDao implements PlanResultDao {
  private readonly apiClient = inject(PlanResultApiClient)

  constructor() {}

  public async createPlanResult(
    planId: number,
    body: CreatePlanResultsParams
  ): Promise<CreatePlanResultFileResult[]> {
    return await firstValueFrom(this.apiClient.createPlanResult({ planId, body }))
  }
}
