import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { AbstractControl } from '@angular/forms'
import { NavigationExtras, Router } from '@angular/router'

import { PlanType } from 'formwork-planner-lib'
import { Plan } from '../../../models/plan'
import { PlanSettings } from '../../../models/planSettings'
import { Project } from '../../../models/project'
import { Stock } from '../../../models/stock'
import { PlanSettingsService } from '../../../services/plan-settings.service'
import { Translation } from '../../../services/translation.service'
import { ProjectRepository } from '../../../repositories/project.repository'
import { StockRepository } from '../../../repositories/stock.repository'
import { Subject, takeUntil } from 'rxjs'

@Component({
  selector: 'efp-plansettings-form',
  templateUrl: './plansettings-form.component.html',
  styleUrls: ['./plansettings-form.component.scss'],
})
export class PlansettingsFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() plan?: Plan
  @Input() planSettings?: PlanSettings
  @Input() navLink?: string
  @Input() readOnlyMode = false

  private pageLeave$ = new Subject<void>()

  readonly planType = PlanType

  projectName = ''
  stockName: string | undefined

  get planName(): AbstractControl {
    return this.planSettingsService.planSettingsForm.controls.planName
  }

  get buildType(): AbstractControl {
    return this.planSettingsService.planSettingsForm.controls.buildType
  }

  readonly selectAlert = {
    message: this.translate.translate('PLAN.CALCULATION_WARNING'),
    cssClass: 'selectAlert',
  }

  constructor(
    public readonly planSettingsService: PlanSettingsService,
    public readonly stockRepository: StockRepository,
    public readonly projectRepository: ProjectRepository,
    private readonly translate: Translation,
    private readonly router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    await this.setupSubscriptions()

    this.planSettingsService.planSettingsForm.setValue({
      planName: this.plan?.name ?? '',
      buildType: this.plan?.buildingType ?? '',
    })
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.plan) {
      await this.setupSubscriptions()
    }
  }

  async ngOnDestroy(): Promise<void> {
    this.pageLeave$.next()
  }

  private async setupSubscriptions(): Promise<void> {
    this.pageLeave$.next()
    this.pageLeave$ = new Subject<void>()

    this.setStockName()
    this.setProjectName()

    this.stockRepository.stocks$.pipe(takeUntil(this.pageLeave$)).subscribe((stocks) => {
      this.setStockName(stocks)
    })
    this.projectRepository.projects$.pipe(takeUntil(this.pageLeave$)).subscribe((projects) => {
      this.setProjectName(projects)
    })

    void this.stockRepository.fetchAll()
    void this.projectRepository.fetchAll(false)
  }

  changeBuildType(): void {
    if (this.buildType?.value && this.plan) {
      this.plan.buildingType = this.buildType.value
    }
  }

  changeName(): void {
    if (this.planName?.value && this.plan) {
      this.plan.name = this.planName.value
    }
  }

  setProjectName(projects?: Project[]): void {
    const projectId = this.plan?.projectId
    let newName = ''
    if (projectId !== undefined && projects !== undefined) {
      newName = projects.find((project) => project.id === projectId)?.name ?? ''
    }

    this.projectName = newName
  }

  setStockName(stocks?: Stock[]): void {
    const stockId = this.plan?.stockId
    let newName = ''
    if (stockId !== undefined && stocks !== undefined) {
      newName = stocks.find((stock) => stock.id === stockId)?.name ?? ''
    }

    this.stockName = newName
  }

  async navigateToProjects(): Promise<void> {
    let backNavOptions: NavigationExtras | undefined = undefined
    if (this.navLink) {
      const stockId = this.plan?.stockId ?? undefined
      backNavOptions = { queryParams: { backUrl: this.navLink, stockId } }
    }
    await this.router.navigate(['/projectoverview'], {
      queryParams: {
        pageMode: 'isSelectMode',
        backUrl: this.router.url.split('?')[0],
        backNavOptions: JSON.stringify(backNavOptions),
      },
      queryParamsHandling: 'merge',
    })
  }

  async navigateToStocks(): Promise<void> {
    let backNavOptions: NavigationExtras | undefined = undefined
    if (this.navLink) {
      const projectId = this.plan?.projectId !== undefined ? this.plan?.projectId : undefined
      backNavOptions = {
        queryParams: { backUrl: this.navLink, projectId, stockId: this.plan?.stockId },
      }
    }
    await this.router.navigate(['/stock'], {
      queryParams: {
        pageMode: 'isSelectMode',
        backUrl: this.router.url.split('?')[0],
        backNavOptions: JSON.stringify(backNavOptions),
      },
      queryParamsHandling: 'merge',
    })
  }
}
