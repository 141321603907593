import { Injectable } from '@angular/core'
import { NavStep } from '../../models/navSteps'
import { Plan } from '../../models/plan'
import { PlanListModel } from '../../../generated/efp-api'

@Injectable()
export abstract class PlanDao {
  protected constructor() {}

  public abstract updatePlan(
    plan: Pick<
      Plan,
      | 'id'
      | 'name'
      | 'date'
      | 'buildingType'
      | 'stockId'
      | 'projectId'
      | 'lastUsed'
      | 'isDoka360'
      | 'isCompleted'
    >
  ): Promise<Plan>

  public abstract updateSerializedMesh(
    id: number,
    serializedMesh?: string,
    lastUsed?: Date
  ): Promise<Plan>

  public abstract updateCurrentStep(
    planId: number,
    currentStep: NavStep,
    lastUsed?: Date
  ): Promise<Plan>

  public abstract findOne(planId: number): Promise<Plan | undefined>

  public abstract findOneBySettingsId(settingsId: number): Promise<Plan | undefined>

  public abstract findAllByProjectId(projectId: number): Promise<Plan[]>

  public abstract findAllByFavouriteId(favouriteId: number): Promise<Plan[]>

  public abstract findAllByStockId(stockId: number): Promise<Plan[]>

  public abstract findAllWithSerializedMesh(): Promise<Plan[]>

  public abstract findAllPlans(): Promise<Plan[]>

  public abstract findAllPlansWith(limit: number, offset: number): Promise<PlanListModel>

  public abstract findRecentPlans(amount: number): Promise<Plan[]>

  public abstract count(): Promise<number>

  public abstract countStocksById(stockId: number): Promise<number>

  public abstract deleteWithAllRelated(plan: Plan): Promise<void>
}
