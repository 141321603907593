<ion-header class="ion-no-border">
  <ion-toolbar class="header-toolbar">
    <div slot="end">
      <button (click)="closeModal()" class="mr-md action-icon action-icon-large">close</button>
    </div>
    <ion-title size="large">
      {{ findModalTitle() }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<efp-modal-form>
  <div class="modal-inner">
    <form [formGroup]="feedbackForm" *ngIf="!feedbackSent; else confirmationMessage">
      <div [ngClass]="{ hidden: index !== 0 }">
        <ion-title> {{ 'FEEDBACK.ALERT_DIALOG_MESSAGE' | translate }}</ion-title>
        <ion-radio-group value="bug" formControlName="feedbackType">
          <ion-item lines="none">
            <ion-radio value="BUG" labelPlacement="end" justify="start">{{
              'FEEDBACK.MAIL_TYPE_BUG' | translate
            }}</ion-radio>
          </ion-item>
          <ion-item lines="none">
            <ion-radio labelPlacement="end" value="HELP" justify="start">{{
              'FEEDBACK.MAIL_TYPE_HELP' | translate
            }}</ion-radio>
          </ion-item>
          <ion-item lines="none">
            <ion-radio labelPlacement="end" value="IDEA" justify="start">{{
              'FEEDBACK.MAIL_TYPE_IDEA' | translate
            }}</ion-radio>
          </ion-item>
        </ion-radio-group>
      </div>

      <div [ngClass]="index !== 1 ? 'hidden' : ''" class="gap-6 d-flex-col">
        <div>
          <ion-title
            >{{ 'FEEDBACK.MAIL_ADD_FEEDBACK' | translate }}
            <ion-note>({{ 'GENERAL.REQUIRED' | translate }})</ion-note></ion-title
          >
          <ion-item lines="none">
            <ion-textarea
              formControlName="feedbackMessage"
              placeholder="{{ 'FEEDBACK.TEXTFIELD_PLACEHOLDER' | translate }}"
              [rows]="6"
              [autoGrow]="false">
            </ion-textarea>
          </ion-item>
        </div>
        <div>
          <ion-title>{{ 'FEEDBACK.WHICH_ATTACHMENTS' | translate }}</ion-title>
          <ion-note class="italic">{{ 'FEEDBACK.ATTACHMENT_HINT' | translate }}</ion-note>
          <div class="d-flex align-center" *ngIf="this.downloadScreenshotUrl">
            <ion-item lines="none" class="flex-1 d-flex">
              <ion-checkbox
                formControlName="attachScreenshot"
                justify="start"
                labelPlacement="end"
                >{{ 'FEEDBACK.SCREENSHOT' | translate }}</ion-checkbox
              >
            </ion-item>
            <button class="download-btn" fill="clear" shape="round" (click)="downloadScreenshot()">
              <ion-icon slot="icon-only" src="assets/icon/ic_download.svg"></ion-icon>
            </button>
          </div>

          <div class="d-flex align-center" *ngIf="this.planName && this.planData">
            <ion-item lines="none" class="flex-1 d-flex">
              <ion-checkbox labelPlacement="end" formControlName="attachPlanData" justify="start">
                {{ 'FEEDBACK.PLAN_FILE' | translate }} ({{ this.planName }})</ion-checkbox
              >
            </ion-item>
            <button class="download-btn" fill="clear" shape="round" (click)="downloadPlanFile()">
              <ion-icon slot="icon-only" src="assets/icon/ic_download.svg"></ion-icon>
            </button>
          </div>

          <ion-item lines="none">
            <ion-checkbox
              justify="start"
              labelPlacement="end"
              formControlName="attachContextInformation">
              <ng-container *ngIf="isNative; else webTranslation">
                {{ 'FEEDBACK.CONTEXT_INFO_MOBILE' | translate }}
              </ng-container>
              <ng-template #webTranslation>
                {{ 'FEEDBACK.CONTEXT_INFO_WEB' | translate }}
              </ng-template>
            </ion-checkbox>
          </ion-item>

          <div class="d-flex align-center" *ngIf="this.showMessageCheckbox">
            <ion-item lines="none" class="flex-1 d-flex">
              <ion-checkbox
                slot="start"
                formControlName="attachResultProtocol"
                justify="start"
                labelPlacement="end">
                {{ 'FEEDBACK.ERROR_MESSAGES' | translate }}
              </ion-checkbox>
            </ion-item>
          </div>
        </div>
      </div>
    </form>
    <ng-template #confirmationMessage>
      <div class="gap-4 d-flex-col">
        <div>
          {{ 'FEEDBACK.MAIL_THANKS' | translate }}
        </div>
        <div>
          {{ 'FEEDBACK.MAIL_SENT_TO_TEXT' | translate }}
        </div>
        <ion-title data-hj-suppress>{{ userMail }}</ion-title>
      </div>
    </ng-template>
  </div>
</efp-modal-form>

<efp-steps-modal-footer *ngIf="!feedbackSent; else acceptNotice">
  <button
    button-start
    *ngIf="index !== 0"
    (click)="goToSlide(this.index - 1)"
    class="action-icon action-icon-large"
    data-test-selector="feedback-modal-modal-prev-slide">
    chevron_backward
  </button>
  <ng-container status-indicators>
    <ion-icon
      (click)="goToSlide(0)"
      data-test-selector="feedback-modal-hint-status-indicator"
      aria-hidden="true"
      src="assets/icon/ellipse.svg"
      [ngClass]="{ active: index === 0 }"></ion-icon>
    <ion-icon
      (click)="goToSlide(1)"
      data-test-selector="feedback-modal-hint-status-indicator"
      aria-hidden="true"
      [ngClass]="{ active: index === 1 }"
      src="assets/icon/ellipse.svg"></ion-icon>
  </ng-container>
  <ng-container button-end>
    <button
      *ngIf="index !== 1"
      (click)="goToSlide(this.index + 1)"
      class="action-icon action-icon-large"
      data-test-selector="feedback-modal-next-slide">
      chevron_forward
    </button>
    <button
      (click)="onSubmit()"
      *ngIf="index === 1"
      [disabled]="!isFeedbackFormValid"
      class="action-icon action-icon-large"
      data-test-selector="feedback-modal-submits">
      send
    </button>
  </ng-container>
</efp-steps-modal-footer>
<ng-template #acceptNotice>
  <efp-steps-modal-footer>
    <button
      button-end
      (click)="closeModal()"
      class="action-icon action-icon-large"
      data-test-selector="onboarding-modal-next-hint">
      check
    </button>
  </efp-steps-modal-footer>
</ng-template>
