import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { DokaCommonModule, DokaToastService, ToastDetails, ToastType } from '@doka-shared/common'
import { TranslateModule } from '@ngx-translate/core'
import { ActivatedRoute } from '@angular/router'
import { Translation } from '../../../../services/translation.service'
import { CalculationResultRepository } from '../../../../repositories/calculation-result.repository'
import { ScreenshotService } from '../../../../services/screenshot.service'
import { GeneratePDFService } from '../../../../services/generate-pdf.service'
import { Plan } from '../../../../models/plan'
import { PlanRepository } from '../../../../repositories/plan.repository'
import { PlanSettingsService } from '../../../../services/plan-settings.service'
import { PdfWorkerResponse } from '../../../../worker/resultPdfWorkerData'
import { PlanResultRepository } from '../../../../repositories/plan-result.repository'
import { PartListService } from '../../../../services/part-list-service'
import { Screenshot } from '../../../../models/screenshot'
import {
  CreatePlanResultArticleParams,
  CreatePlanResultFileParams,
  CreatePlanResultFileResult,
  CreatePlanResultsParams,
  FileType,
} from '../../../../../generated/efp-api'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { catchError, throwError } from 'rxjs'

export interface PlanResultFile extends CreatePlanResultFileParams {
  fileData: Blob
}
@Component({
  selector: 'efp-upload-360',
  templateUrl: './upload-360.component.html',
  standalone: true,
  imports: [DokaCommonModule, CommonModule, TranslateModule],
})
export class Upload360Component implements OnInit {
  public plan?: Plan
  public planId = this.activatedRoute.snapshot.params.planId
  constructor(
    private readonly calculationResultRepository: CalculationResultRepository,
    private readonly planRepository: PlanRepository,
    private readonly activatedRoute: ActivatedRoute,
    private readonly toastService: DokaToastService,
    private readonly translate: Translation,
    private readonly screenshotService: ScreenshotService,
    private readonly generatePDFService: GeneratePDFService,
    private readonly planSettingsService: PlanSettingsService,
    private readonly planResultRepository: PlanResultRepository,
    private readonly partListService: PartListService,
    private readonly httpClient: HttpClient
  ) {}

  async ngOnInit(): Promise<void> {
    this.plan = await this.planRepository.findOne(this.planId)
  }

  public isPublishing = false

  async publishResult(): Promise<void> {
    this.isPublishing = true

    try {
      await this.requestStorageInfoAndUploadArticleResult()

      const toast = await this.toastService.create(
        {
          title: this.translate.translate('GENERAL.SUCCESS'),
          message: this.translate.translate('PLAN.PUBLISH_RESULT_360.SUCCESS'),
        },
        ToastType.Success,
        5
      )
      toast.show()
      this.isPublishing = false
    } catch (error: unknown) {
      const details: ToastDetails = {
        title: this.translate.translate('GENERAL.ERROR'),
        message: this.translate.translate('PLAN.PUBLISH_RESULT_360.ERROR'),
      }
      const toastError = await this.toastService.create(details, ToastType.Alert, 5)
      toastError.show()
      this.isPublishing = false
    }
  }

  private async requestStorageInfoAndUploadArticleResult(): Promise<void> {
    const resultFiles: PlanResultFile[] = await this.generateResultFiles()
    const requestFiles = resultFiles.map((file) => {
      return {
        fileName: file.fileName,
        fileType: file.fileType,
        fileSize: file.fileSize,
        fileCreationDate: file.fileCreationDate,
        fileModificationDate: file.fileModificationDate,
      } as CreatePlanResultFileParams
    })

    const cycleArticles = await this.getPieceLists()

    const requestBody: CreatePlanResultsParams = {
      articles: cycleArticles,
      files: requestFiles,
    }
    const uploadFilesInfo: CreatePlanResultFileResult[] =
      await this.planResultRepository.createPlanResult(this.planId, requestBody)

    await Promise.all(
      resultFiles.map(async (file: PlanResultFile) => {
        const uploadUrl = uploadFilesInfo.find(
          (resultFile) => resultFile.fileName === file.fileName
        )?.sasUploadUri

        if (uploadUrl && file.fileData) {
          return this.uploadFile(file.fileData, uploadUrl, file.fileType)
        } else {
          throw new Error('Upload URL or file data is null')
        }
      })
    )
  }

  private async generateResultFiles(): Promise<PlanResultFile[]> {
    const [pdfResult, resultXML, screenshots] = await Promise.all([
      this.generatePDF(),
      this.calculationResultRepository.getResultXML(this.planId),
      this.plan ? this.screenshotService.loadData(this.plan) : Promise.resolve([]),
    ])

    const files: PlanResultFile[] = []

    await this.addFileIfExists(files, pdfResult, 'result_summary', FileType.Pdf)
    await this.addFileIfExists(files, resultXML, 'result', FileType.Xml)
    await Promise.all(
      screenshots.map(async (screen) =>
        this.addFileIfExists(files, screen, screen.name, FileType.Png)
      )
    )

    return files
  }

  private async getPieceLists(): Promise<CreatePlanResultArticleParams[]> {
    const partList = await this.calculationResultRepository.getArticleListWithCycleUsageForPlan(
      this.planId
    )
    if (partList) {
      const partListWithChangedAmounts = await this.partListService.updatePartListForPlan(
        this.planId,
        partList.parts
      )

      const partsWithoutZero = partList.parts.filter((part) => part.amount > 0) // we want only the parts which are changed to zero, not all zero parts
      const changedPartList = partListWithChangedAmounts.filter(
        (part) => part.amount !== 0 || partsWithoutZero.find((p) => p.articleId === part.articleId)
      )

      const planResultArticles: CreatePlanResultArticleParams[] = changedPartList.map((part) => {
        return {
          amount: part.amount,
          articleId: part.articleId,
          cyclesUsage: this.mapCyclesUsage(part.cycleUsage),
        } as CreatePlanResultArticleParams
      })
      return planResultArticles
    }
    return [] as CreatePlanResultArticleParams[]
  }

  private mapCyclesUsage(cyclesUsage: number[]): { [key: string]: number } {
    const result: { [key: string]: number } = {}
    cyclesUsage.forEach((usage, index) => {
      if (usage > 0) result[(index + 1).toString()] = usage
    })
    return result
  }

  async uploadFile(file: Blob, uploadUrl: string, fileType: FileType): Promise<void> {
    const httpHeaders = new HttpHeaders({
      'x-ms-blob-type': 'BlockBlob',
      'Content-Type': this.getFileMimeType(fileType),
    })

    this.httpClient
      .put(uploadUrl, file, { headers: httpHeaders })
      .pipe(
        catchError((error) => {
          return throwError(() => new Error(error.message || 'Upload failed'))
        })
      )
      .subscribe()
  }

  /**
   * Helper function to add a file model if the file exists
   */
  private async addFileIfExists(
    requestModels: PlanResultFile[],
    fileData: Screenshot | ArrayBuffer | PdfWorkerResponse | string | null | undefined,
    fileName: string,
    fileType: FileType
  ): Promise<void> {
    if (fileData) {
      const blob = await this.getBlob(fileData, fileType)
      const file: PlanResultFile = {
        fileName,
        fileType,
        fileSize: blob.size,
        fileCreationDate: new Date().toISOString(),
        fileModificationDate: new Date().toISOString(),
        fileData: blob,
      }
      requestModels.push(file)
    } else throw new Error('File does not exist')
  }

  // Helper function to determine the MIME type
  private getFileMimeType(fileType: FileType): string {
    switch (fileType) {
      case FileType.Pdf:
        return 'application/pdf'
      case FileType.Png:
        return 'image/png'
      case FileType.Jpg:
        return 'image/jpeg'
      case FileType.Xml:
        return 'application/xml'
      default:
        return 'application/octet-stream'
    }
  }

  private async getBlob(
    file: string | PdfWorkerResponse | Screenshot | ArrayBuffer,
    fileType: FileType
  ): Promise<Blob> {
    let uploadData: Blob | Uint8Array = new Blob()
    switch (fileType) {
      case FileType.Xml:
        uploadData = new Blob([file as string], { type: 'application/xml' })
        break
      case FileType.Png:
        if (this.isScreenshot(file)) {
          const blob = this.convertScreenshotSrcToBlob(file.screenshot)
          if (blob) uploadData = blob
          break
        }
        break
      case FileType.Pdf:
        if (this.isPDF(file)) {
          uploadData = file.pdfBlob
          break
        }
        break
      default:
        uploadData = new Blob([file as ArrayBuffer], { type: 'application/octet-stream' })
        break
    }

    return uploadData
  }

  private isPDF(
    file: string | ArrayBuffer | PdfWorkerResponse | Screenshot
  ): file is PdfWorkerResponse {
    return (file as PdfWorkerResponse).isPdfWorkerResponse
  }

  private isScreenshot(
    file: string | ArrayBuffer | PdfWorkerResponse | Screenshot
  ): file is Screenshot {
    return (file as Screenshot).screenshot !== undefined
  }

  private convertScreenshotSrcToBlob(base64String: string): Blob {
    const base64Data = base64String.replace(/^data:image\/\w+;base64,/, '')
    const binaryData = atob(base64Data)
    const arrayBuffer = new ArrayBuffer(binaryData.length)
    const uint8Array = new Uint8Array(arrayBuffer)

    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i)
    }
    return new Blob([uint8Array], { type: 'image/png' })
  }

  private async generatePDF(): Promise<PdfWorkerResponse | undefined> {
    if (!this.plan) return
    const screens = await this.screenshotService.loadData(this.plan)
    const planSettings = await this.planSettingsService.getPlanSettingsAndSetLastUnit(
      this.plan.settingsId
    )

    if (!planSettings) return
    return await this.generatePDFService.generatePDF(
      this.plan,
      planSettings,
      screens,
      false,
      true,
      ''
    )
  }
}
