import { Injectable } from '@angular/core'
import { CalculationResult } from '../../models/calculationResult'
import {
  CreateCalculationResultCommandParam,
  CalculationResultImageModel,
  CalculationResultModel,
  CalculationResultThumbnailModel,
} from '../../../generated/efp-api'

export const mapCalculationResultToCreateCalculationResultCommandParam = (
  calculationResult: CalculationResult
): CreateCalculationResultCommandParam => {
  return {
    resultBase64Image: calculationResult.resultBase64Image ?? '',
    resultBase64Thumbnail: calculationResult.resultBase64Thumbnail ?? '',
    resultProtocol: calculationResult.resultProtocol
      ? JSON.stringify(calculationResult.resultProtocol)
      : null,
    resultXML: calculationResult.resultXML ?? '',
    partList: JSON.stringify(calculationResult.partList),
  }
}

export const mapCalculationResultModelToCalculationResult = (
  calculationResult: CalculationResultModel
): CalculationResult => {
  return {
    planId: calculationResult.planId,
    resultProtocol: calculationResult.resultProtocol
      ? JSON.parse(calculationResult.resultProtocol)
      : null,
    resultXML: calculationResult.resultXml,
    partList: JSON.parse(calculationResult.partList),
  }
}

@Injectable()
export abstract class CalculationResultDao {
  abstract saveCalculationResult(
    calculationResult: Omit<CalculationResult, 'planId'>,
    planId: number
  ): Promise<void>

  abstract getCalculationResult(planId: number): Promise<CalculationResult | undefined>

  abstract getCalculationResultImage(
    planId: number
  ): Promise<CalculationResultImageModel | undefined>

  abstract getCalculationResultThumbnail(
    planId: number
  ): Promise<CalculationResultThumbnailModel | undefined>

  abstract getIsCalculated(planId: number): Promise<boolean>
}
