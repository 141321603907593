import { Injectable } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { ViewDirection } from 'efp-viewer'
import { PlanType } from 'formwork-planner-lib'
import { firstValueFrom, of } from 'rxjs'
import { delay } from 'rxjs/operators'
import { Plan } from '../models/plan'
import { Screenshot } from '../models/screenshot'
import { PdfExportComponent } from '../pages/result/components/result-2d-viewer/pdf-export/pdf-export.component'
import { Result3dComponent } from '../pages/result/components/result-3d/result-3d.component'
import { LoadingSpinnerService } from './loading-spinner.service'
import { Translation } from './translation.service'
import { ScreenshotCommandParams } from '../../generated/efp-api'
import { CalculationResultRepository } from '../repositories/calculation-result.repository'
import { ScreenshotRepository } from '../repositories/screenshot.repository'
import { mapScreenshotToScreenshotModel } from './dao/screenshot.dao'

@Injectable({
  providedIn: 'root',
})
export class ScreenshotService {
  constructor(
    private readonly screenshotRepository: ScreenshotRepository,
    private readonly loadingSpinner: LoadingSpinnerService,
    private readonly translate: Translation,
    private readonly modalCtrl: ModalController,
    private readonly calculationResultRepository: CalculationResultRepository
  ) {}

  async insertScreen(screen: Screenshot): Promise<number> {
    return this.loadingSpinner.doWithLoadingSpinner(async () => {
      // Get sure width/height are integers (backend validation failes with decimals places)
      screen.width = Math.round(screen.width)
      screen.height = Math.round(screen.height)
      const insertedScreen = await this.screenshotRepository.create(
        mapScreenshotToScreenshotModel(screen)
      )
      return insertedScreen.id
    })
  }

  public async getScreensFromPlan(planId: number): Promise<Screenshot[]> {
    return await this.screenshotRepository.findAllByPlanId(planId)
  }

  public async deleteScreen(screenId: number): Promise<void> {
    return await this.screenshotRepository.delete(screenId)
  }

  public async updateScreenName(screenshot: Screenshot, newName: string): Promise<void> {
    await this.screenshotRepository.updateName(screenshot.id, newName)
    screenshot.name = newName
  }

  public async loadData(plan: Plan): Promise<Screenshot[]> {
    return this.loadingSpinner.doWithLoadingSpinner(async () => {
      // in case screenshot generation not finishing
      const screens: Screenshot[] | undefined = await Promise.race([
        firstValueFrom(of([]).pipe(delay(25000))),
        this.getOrCreateScreenshots(plan),
      ])

      return screens
    })
  }

  private async getOrCreateScreenshots(plan: Plan): Promise<Screenshot[]> {
    const screens = await this.getScreensFromPlan(plan.id)
    if (screens.length > 0) return screens
    const isSlab = plan.buildingType === PlanType.SLAB
    let allScreens: Screenshot[] = []

    if (screens.filter((x) => x.defaultView).length > 0) {
      return screens
    }

    if (screens.length > 0) {
      allScreens = screens
    }

    if (!isSlab) {
      const cycleScreens = await Result3dComponent.viewerCustomScreen?.requestDefaultScreenshots()
      if (!cycleScreens) {
        console.error('### Result3dComponent.viewerCustomScreen is not initialized')
        return allScreens
      }

      for (const item of cycleScreens) {
        // 888 = whole image of plan without articles
        const name =
          item.cycle !== 888 && item.direction !== undefined
            ? this.translate.translate('VIEWER.CYCLE') +
              ' ' +
              item.cycle +
              ' ' +
              ViewDirection[item.direction]
            : this.translate.translate('VIEWER.PLAN')

        const cycleScreen: ScreenshotCommandParams = {
          name,
          date: new Date().toISOString(),
          planId: plan.id,
          screenshotData: item.base64,
          defaultView: true,
          cycle: item.cycle ? item.cycle : 0,
          width: item.width,
          height: item.height,
        }

        allScreens.push(await this.screenshotRepository.create(cycleScreen))
      }
    } else if (await this.calculationResultRepository.getIsCalculated(plan.id)) {
      const base64 = await this.calculationResultRepository.getResultImage(plan.id)
      if (base64) {
        const img = new Image()
        const loadImage = new Promise<Screenshot>((resolve, reject) => {
          img.onload = () => {
            const tiposScreen: Screenshot = {
              id: 0,
              name: this.translate.translate('SCREENSHOT.DEFAULT_SLAB'),
              date: new Date(),
              planId: plan.id,
              screenshot: base64,
              defaultView: true,
              cycle: 0,
              width: img.width,
              height: img.height,
            }

            resolve(tiposScreen)
          }
          img.onerror = (e) => reject(e)
        })

        img.src = base64

        const screenshot = await loadImage
        // ios workaround - somehow the path gets invalid or unsecure
        allScreens = allScreens.filter((x) => !x.defaultView)

        allScreens.push(screenshot)
      }
    }

    return allScreens
  }

  public generatePDF(screens: Screenshot[], planId: number): void {
    void this.modalCtrl
      .create({
        component: PdfExportComponent,
        componentProps: { screens, planId },
        initialBreakpoint: 1,
      })
      .then(async (modal) => {
        await modal.present()
      })
  }
}
