<efp-app-header
  class="no-header-shadow"
  [isNavStep]="true"
  navLink="/homepage"
  title="{{ plan$.value?.name }}"
  [planId]="planId"
  [webCollapsableEnabled]="true"
  [showTitleOnWeb]="true">
  <ng-container buttons *ngIf="plan$ | async as plan">
    <button
      [disabled]="efpExportService.isLoading"
      class="headerbar-action"
      (click)="efpExportService.showShareDialog(plan)">
      <div class="headerbar-action-icon">share</div>
    </button>
  </ng-container>
</efp-app-header>
<efp-sidebar-container [sidebarPosition]="SidebarPosition.RIGHT">
  <ng-container
    *ngIf="(planType$ | async) === PlanType.WALL"
    [efpOnboardingTrigger]="firstStepsOnboardingId()"
    [triggeredBy]="TriggerType.COMPONENT_RENDERED">
    <efp-onboarding-hints [onboardingHintSeriesKey]="firstStepsOnboardingId()">
    </efp-onboarding-hints>
  </ng-container>
  <div
    class="drawing-area-container"
    efpKeyboardShortcut
    (deletionShortcut)="onDelete()"
    (mouseleave)="onMouseLeave()"
    (ctrlShortcut)="onCtrl($event)"
    (dShortcut)="changeModeToDrawing()"
    (eShortcut)="changeModeToEdit()"
    (ctrlZShortcut)="undo()"
    (ctrlYShortcut)="redo()"
    (numberShortcut)="onKeyboardNumberPressed()">
    <efp-planner
      #efpPlanner
      [disabled]="!!planReadOnly"
      [initialize]="pageReady"
      [showScale]="false"
      [scaleUnit]="unit"
      [scaleLabel]="'PLAN.SCALE' | translate"
      (ready)="onPlannerInitialized()"
      (interactionDragged)="onMouseDragged($event)"
      (interactionUp)="onMouseUp($event)"
      (interactionMoved)="onMouseMoved($event)"
      (zoomChanged)="onZoomUpdated($event)"
      (panChanged)="onPanUpdated($event)">
      <efp-t-wall-editor
        *ngIf="
          isWebversion && selectedMeasure && selectedTWall && edgeForMeasureEdit && plan$.value
        "
        #tWallEditor
        overlay
        [style.left.px]="measurementEditorOverlayPosition.x"
        [style.top.px]="measurementEditorOverlayPosition.y"
        [style.position]="'absolute'"
        [plan]="plan$.value"
        [model]="model"
        [previewModel]="previewModel"
        [tWall]="selectedTWall"
        [previewTWall]="previewEdgeForMeasureEdit"
        [selectedMeasure]="selectedMeasure"
        [measuredEdge]="edgeForMeasureEdit"
        (closeMenu)="closeMeasureOrTWallEditor()">
      </efp-t-wall-editor>

      <efp-measurement-editor
        *ngIf="isWebversion && edgeForMeasureEdit && plan$.value && !selectedTWall"
        #measurementEditor
        overlay
        [style.left.px]="measurementEditorOverlayPosition.x"
        [style.top.px]="measurementEditorOverlayPosition.y"
        [style.position]="'absolute'"
        [plan]="plan$.value"
        [model]="model"
        [previewModel]="previewModel"
        [edge]="edgeForMeasureEdit"
        [previewEdge]="previewEdgeForMeasureEdit"
        [selectedMeasure]="selectedMeasure"
        [showButton]="showDirectionButtonsAndPreview"
        (closeMenu)="closeMeasureOrTWallEditor()"
        (directionChanged)="updateLabels()"
        (changesApplied)="onMeshChanged($event)">
      </efp-measurement-editor>

      <efp-angle-menu
        *ngIf="
          isWebversion &&
          selectedAngle &&
          model &&
          !(edgeForMeasureEdit && plan$.value && !selectedTWall)
        "
        #angleEditor
        overlay
        [style.left.px]="measurementEditorOverlayPosition.x"
        [style.top.px]="measurementEditorOverlayPosition.y"
        [style.position]="'absolute'"
        [editedAngle]="selectedAngle"
        [previewAngle]="previewSelectedAngle"
        [model]="model"
        [previewModel]="previewModel"
        (closeMenu)="closeAngleMenu()">
      </efp-angle-menu>
    </efp-planner>
    <efp-measurement-legend
      [wallHeight]="planSettings ? planSettings.wallHeight : undefined"
      [slabThickness]="planSettings ? planSettings.slabThickness : undefined"
      [slabHeight]="planSettings ? planSettings.slabHeight : undefined"
      [measurementUnitPlanner]="planSettings ? planSettings.measurementUnit : undefined"
      [buildingType]="plan$.value ? plan$.value.buildingType : undefined"></efp-measurement-legend>
  </div>

  <efp-bottom-menu-container
    *ngIf="plannerInitialized && model && renderService && plan$.value && !planReadOnly">
    <efp-primary-planner-toolbar
      *ngIf="planVisibilitySettings"
      [renderService]="renderService"
      [planVisibilitySettings]="planVisibilitySettings"
      [model]="model"
      [mode]="this.mode">
      <div class="action-icon-switch md:action-icon-switch-vertical highlight-planner-hint-1">
        <button
          class="action-icon action-icon-large"
          efpTooltip
          [tooltipTitle]="'TOOLBAR.DRAW_MODE' | translate"
          [ngClass]="{ 'action-icon-activated': mode === modes.DRAW }"
          (click)="changeMode(modes.DRAW)"
          data-test-selector="planner-draw-mode">
          draw
        </button>
        <button
          class="action-icon action-icon-large"
          efpTooltip
          [tooltipTitle]="'TOOLBAR.EDIT_MODE' | translate"
          [ngClass]="{ 'action-icon-activated': mode === modes.DEFORMATION }"
          (click)="changeMode(modes.DEFORMATION)"
          data-test-selector="planner-deformation-mode">
          shapes
        </button>
      </div>
      <efp-undo-redo
        [history]="model.history"
        (undo)="undo()"
        (redo)="redo()"
        [selectionOpen]="isMeasureSelectionOpen">
      </efp-undo-redo>

      <efp-planner-context-menu
        [model]="model"
        [selectedEdges]="edgeSelectionService?.getSelectedEdges()"
        (editMeasure)="onEditEdgeMeasurements($event)"
        (deleteClicked)="selectedTWall = undefined"
        [measureSelectionOpen]="isMeasureSelectionOpen">
      </efp-planner-context-menu>
      <efp-toolbar-divider></efp-toolbar-divider>

      <button
        class="action-icon action-icon-large"
        *ngIf="plan$.value"
        [routerLink]="['/drawingsettings', plan$.value.id]"
        (click)="onMouseLeave()"
        efpTooltip
        [tooltipTitle]="'TOOLBAR.DRAWING_SETTINGS' | translate">
        settings
      </button>
    </efp-primary-planner-toolbar>
  </efp-bottom-menu-container>
  <efp-context-menu-center-button-container
    *ngIf="!isWebversion"
    [centerContextMenu]="true"
    [model]="model"
    [useCenterView]="
      selectedMeasure !== undefined ||
      selectedTWall !== undefined ||
      edgeForMeasureEdit !== undefined ||
      selectedAngle !== undefined
    ">
    <ng-container
      *ngIf="
        selectedMeasure && selectedTWall && edgeForMeasureEdit && plan$.value;
        else showMeasurementEditor
      ">
      <efp-t-wall-editor
        #tWallEditor
        [plan]="plan$.value"
        [model]="model"
        [previewModel]="previewModel"
        [tWall]="selectedTWall"
        [previewTWall]="previewEdgeForMeasureEdit"
        [selectedMeasure]="selectedMeasure"
        [measuredEdge]="edgeForMeasureEdit"
        (closeMenu)="closeMeasureOrTWallEditor()"></efp-t-wall-editor>
    </ng-container>

    <ng-template #showMeasurementEditor>
      <efp-measurement-editor
        *ngIf="!isWebversion && edgeForMeasureEdit && plan$.value; else showAngleMenu"
        #measurementEditor
        [plan]="plan$.value"
        [model]="model"
        [previewModel]="previewModel"
        [edge]="edgeForMeasureEdit"
        [previewEdge]="previewEdgeForMeasureEdit"
        [selectedMeasure]="selectedMeasure"
        (closeMenu)="closeMeasureOrTWallEditor()"
        (directionChanged)="updateLabels()"
        (changesApplied)="onMeshChanged($event)"></efp-measurement-editor>
    </ng-template>

    <ng-template #showAngleMenu>
      <efp-angle-menu
        #angleEditor
        *ngIf="!isWebversion && selectedAngle && model"
        [editedAngle]="selectedAngle"
        [previewAngle]="previewSelectedAngle"
        [model]="model"
        [previewModel]="previewModel"
        (closeMenu)="closeAngleMenu()"></efp-angle-menu>
    </ng-template>
  </efp-context-menu-center-button-container>
  <ng-container *ngIf="planSettings && plan$.value" rightSidebar>
    <efp-navbar
      currentStep="planner"
      [planId]="plan$.value.id"
      [planType]="plan$.value.buildingType"
      [enableNavigation]="false"
      [planSettings]="planSettings"
      (navButtonClicked)="onNextClicked($event)">
    </efp-navbar>
  </ng-container>
</efp-sidebar-container>
