/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseApiClient } from '../base-api-client';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CalculationRequestCommand } from '../models/calculation-request-command';
import { CalculationTiposResultModel } from '../models/calculation-tipos-result-model';

@Injectable({ providedIn: 'root' })
export class CalculationsApiClient extends BaseApiClient {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sendCalculationRequest()` */
  static readonly SendCalculationRequestPath = '/api/v1/calculations';

  /**
   * Processes a calculation request with tipos.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendCalculationRequest$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendCalculationRequest$Plain$Response(
    params: {
      body: CalculationRequestCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CalculationTiposResultModel>> {
    const rb = new RequestBuilder(this.rootUrl, CalculationsApiClient.SendCalculationRequestPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalculationTiposResultModel>;
      })
    );
  }

  /**
   * Processes a calculation request with tipos.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendCalculationRequest$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendCalculationRequest$Plain(
    params: {
      body: CalculationRequestCommand
    },
    context?: HttpContext
  ): Observable<CalculationTiposResultModel> {
    return this.sendCalculationRequest$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CalculationTiposResultModel>): CalculationTiposResultModel => r.body)
    );
  }

  /**
   * Processes a calculation request with tipos.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendCalculationRequest()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendCalculationRequest$Response(
    params: {
      body: CalculationRequestCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CalculationTiposResultModel>> {
    const rb = new RequestBuilder(this.rootUrl, CalculationsApiClient.SendCalculationRequestPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalculationTiposResultModel>;
      })
    );
  }

  /**
   * Processes a calculation request with tipos.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendCalculationRequest$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendCalculationRequest(
    params: {
      body: CalculationRequestCommand
    },
    context?: HttpContext
  ): Observable<CalculationTiposResultModel> {
    return this.sendCalculationRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<CalculationTiposResultModel>): CalculationTiposResultModel => r.body)
    );
  }

}
