/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseApiClient } from '../base-api-client';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CalculationResultImageModel } from '../models/calculation-result-image-model';
import { CalculationResultModel } from '../models/calculation-result-model';
import { CalculationResultThumbnailModel } from '../models/calculation-result-thumbnail-model';
import { CreateCalculationResultCommandParam } from '../models/create-calculation-result-command-param';

@Injectable({ providedIn: 'root' })
export class CalculationResultsApiClient extends BaseApiClient {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCalculationResult()` */
  static readonly GetCalculationResultPath = '/api/v1/plans/{planId}/calculation-results';

  /**
   * Get calculationResult per plan id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCalculationResult$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalculationResult$Plain$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CalculationResultModel>> {
    const rb = new RequestBuilder(this.rootUrl, CalculationResultsApiClient.GetCalculationResultPath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalculationResultModel>;
      })
    );
  }

  /**
   * Get calculationResult per plan id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCalculationResult$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalculationResult$Plain(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<CalculationResultModel> {
    return this.getCalculationResult$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CalculationResultModel>): CalculationResultModel => r.body)
    );
  }

  /**
   * Get calculationResult per plan id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCalculationResult()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalculationResult$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CalculationResultModel>> {
    const rb = new RequestBuilder(this.rootUrl, CalculationResultsApiClient.GetCalculationResultPath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalculationResultModel>;
      })
    );
  }

  /**
   * Get calculationResult per plan id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCalculationResult$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalculationResult(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<CalculationResultModel> {
    return this.getCalculationResult$Response(params, context).pipe(
      map((r: StrictHttpResponse<CalculationResultModel>): CalculationResultModel => r.body)
    );
  }

  /** Path part for operation `createCalculationResult()` */
  static readonly CreateCalculationResultPath = '/api/v1/plans/{planId}/calculation-results';

  /**
   * Add calculationResult entry.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCalculationResult$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCalculationResult$Plain$Response(
    params: {
      planId: number;
      body: CreateCalculationResultCommandParam
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, CalculationResultsApiClient.CreateCalculationResultPath, 'post');
    if (params) {
      rb.path('planId', params.planId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Add calculationResult entry.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCalculationResult$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCalculationResult$Plain(
    params: {
      planId: number;
      body: CreateCalculationResultCommandParam
    },
    context?: HttpContext
  ): Observable<number> {
    return this.createCalculationResult$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * Add calculationResult entry.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCalculationResult()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCalculationResult$Response(
    params: {
      planId: number;
      body: CreateCalculationResultCommandParam
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, CalculationResultsApiClient.CreateCalculationResultPath, 'post');
    if (params) {
      rb.path('planId', params.planId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Add calculationResult entry.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCalculationResult$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCalculationResult(
    params: {
      planId: number;
      body: CreateCalculationResultCommandParam
    },
    context?: HttpContext
  ): Observable<number> {
    return this.createCalculationResult$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `deleteCalculationResult()` */
  static readonly DeleteCalculationResultPath = '/api/v1/plans/{planId}/calculation-results';

  /**
   * Delete calculationResult entry for plan id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCalculationResult()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCalculationResult$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CalculationResultsApiClient.DeleteCalculationResultPath, 'delete');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete calculationResult entry for plan id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCalculationResult$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCalculationResult(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteCalculationResult$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getCalculationResultCalculated()` */
  static readonly GetCalculationResultCalculatedPath = '/api/v1/plans/{planId}/calculation-results/calculated';

  /**
   * Returns true if the plan has a plan result.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCalculationResultCalculated$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalculationResultCalculated$Plain$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, CalculationResultsApiClient.GetCalculationResultCalculatedPath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Returns true if the plan has a plan result.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCalculationResultCalculated$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalculationResultCalculated$Plain(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.getCalculationResultCalculated$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * Returns true if the plan has a plan result.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCalculationResultCalculated()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalculationResultCalculated$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, CalculationResultsApiClient.GetCalculationResultCalculatedPath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Returns true if the plan has a plan result.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCalculationResultCalculated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalculationResultCalculated(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.getCalculationResultCalculated$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `getCalculationResultImage()` */
  static readonly GetCalculationResultImagePath = '/api/v1/plans/{planId}/calculation-results/image';

  /**
   * Get calculationResult image per plan id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCalculationResultImage$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalculationResultImage$Plain$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CalculationResultImageModel>> {
    const rb = new RequestBuilder(this.rootUrl, CalculationResultsApiClient.GetCalculationResultImagePath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalculationResultImageModel>;
      })
    );
  }

  /**
   * Get calculationResult image per plan id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCalculationResultImage$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalculationResultImage$Plain(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<CalculationResultImageModel> {
    return this.getCalculationResultImage$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CalculationResultImageModel>): CalculationResultImageModel => r.body)
    );
  }

  /**
   * Get calculationResult image per plan id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCalculationResultImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalculationResultImage$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CalculationResultImageModel>> {
    const rb = new RequestBuilder(this.rootUrl, CalculationResultsApiClient.GetCalculationResultImagePath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalculationResultImageModel>;
      })
    );
  }

  /**
   * Get calculationResult image per plan id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCalculationResultImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalculationResultImage(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<CalculationResultImageModel> {
    return this.getCalculationResultImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<CalculationResultImageModel>): CalculationResultImageModel => r.body)
    );
  }

  /** Path part for operation `getCalculationResultThumbnail()` */
  static readonly GetCalculationResultThumbnailPath = '/api/v1/plans/{planId}/calculation-results/thumbnail';

  /**
   * Get calculationResult thumbnail per plan id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCalculationResultThumbnail$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalculationResultThumbnail$Plain$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CalculationResultThumbnailModel>> {
    const rb = new RequestBuilder(this.rootUrl, CalculationResultsApiClient.GetCalculationResultThumbnailPath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalculationResultThumbnailModel>;
      })
    );
  }

  /**
   * Get calculationResult thumbnail per plan id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCalculationResultThumbnail$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalculationResultThumbnail$Plain(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<CalculationResultThumbnailModel> {
    return this.getCalculationResultThumbnail$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CalculationResultThumbnailModel>): CalculationResultThumbnailModel => r.body)
    );
  }

  /**
   * Get calculationResult thumbnail per plan id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCalculationResultThumbnail()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalculationResultThumbnail$Response(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CalculationResultThumbnailModel>> {
    const rb = new RequestBuilder(this.rootUrl, CalculationResultsApiClient.GetCalculationResultThumbnailPath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalculationResultThumbnailModel>;
      })
    );
  }

  /**
   * Get calculationResult thumbnail per plan id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCalculationResultThumbnail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalculationResultThumbnail(
    params: {
      planId: number;
    },
    context?: HttpContext
  ): Observable<CalculationResultThumbnailModel> {
    return this.getCalculationResultThumbnail$Response(params, context).pipe(
      map((r: StrictHttpResponse<CalculationResultThumbnailModel>): CalculationResultThumbnailModel => r.body)
    );
  }

}
