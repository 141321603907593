import { Injectable } from '@angular/core'
import { CreatePlanResultFileResult, CreatePlanResultsParams } from '../../generated/efp-api'
import { PlanResultDao } from '../services/dao/plan-result.dao'

@Injectable({
  providedIn: 'root',
})
export class PlanResultRepository {
  constructor(private readonly planResultDao: PlanResultDao) {}

  public async createPlanResult(
    planId: number,
    body: CreatePlanResultsParams
  ): Promise<CreatePlanResultFileResult[]> {
    return await this.planResultDao.createPlanResult(planId, body)
  }
}
