import { Injectable } from '@angular/core'
import { createStore } from '@ngneat/elf'
import {
  deleteEntitiesByPredicate,
  getEntity,
  selectAllEntities,
  upsertEntities,
  withEntities,
} from '@ngneat/elf-entities'
import { PartList, ResultPart } from '../models/part'
import { CalculationResult } from '../models/calculationResult'
import { CalculationResultProtocol } from '../models/calculationResultProtocol'
import { Stock } from '../models/stock'
import { CalculationResultDao } from '../services/dao/calculation-result.dao'
import { PlanRepository } from './plan.repository'
import { StockRepository } from './stock.repository'
import { screenshotStore } from './screenshot.repository'
import { changedResultPartStore } from './changed-result-part.repository'
import { ResetCalculationService } from '../services/cache/reset-calculation.service'
import { CalculationResultResetCalculationDao } from '../services/dao/reset-calculation-result.dao'
import { distinctUntilChanged } from 'rxjs'
import { CalculationResultService } from '../services/plan-calculation.service'

interface CalculationResultCalculated {
  id: number
  calculated: boolean
}

const calculationResultStore = createStore(
  { name: 'calculationResult' },
  withEntities<CalculationResult, 'planId'>({ idKey: 'planId' })
)

const calculationResultCalculatedStore = createStore(
  { name: 'calculationResultCalculated' },
  withEntities<CalculationResultCalculated>()
)

@Injectable({
  providedIn: 'root',
})
export class CalculationResultRepository {
  public readonly calculationResults$ = calculationResultStore.pipe(selectAllEntities())

  public readonly calculationResultCalculationStates$ = calculationResultCalculatedStore.pipe(
    selectAllEntities()
  )

  constructor(
    private readonly calculationResultDao: CalculationResultDao,
    private readonly calculationResultResetCalculationDao: CalculationResultResetCalculationDao,
    private readonly planRepository: PlanRepository,
    private readonly stockRepository: StockRepository,
    private readonly resetCalculationService: ResetCalculationService
  ) {
    this.resetCalculationService.planIdsNeededReCalculation$
      .pipe(
        distinctUntilChanged(
          (prev: number[], curr: number[]) =>
            prev.length === curr.length && prev.every((v, _) => curr.includes(v))
        )
      )
      .subscribe((planIds) => {
        planIds.forEach((planId) => {
          void this.resetCalculation(planId)
        })
      })
  }

  public async saveCalculationResult(
    calculationResult: Omit<CalculationResult, 'planId'>,
    planId: number
  ): Promise<void> {
    await this.calculationResultDao.saveCalculationResult(calculationResult, planId)
    calculationResultStore.update(upsertEntities({ ...calculationResult, planId }))
    calculationResultCalculatedStore.update(upsertEntities({ calculated: true, id: planId }))
  }

  /* TODO: (#79273 & #79272) This should be a private method.

     Don't use this method directly, and try to implement it with the ResetCalculationService instead.
     It's only public for now, because of FavoriteProfile TODOs.
  */
  public async resetCalculation(resetPlanId: number): Promise<void> {
    const isCalculated = await this.getIsCalculated(resetPlanId)
    if (!isCalculated) {
      return
    }

    calculationResultCalculatedStore.update(upsertEntities({ calculated: false, id: resetPlanId }))
    calculationResultStore.update(deleteEntitiesByPredicate(({ planId }) => planId === resetPlanId))
    screenshotStore.update(deleteEntitiesByPredicate(({ planId }) => planId === resetPlanId))
    changedResultPartStore.update(deleteEntitiesByPredicate(({ planId }) => planId === resetPlanId))

    await this.calculationResultResetCalculationDao.resetCalculation(resetPlanId).catch(() => {
      // If the reset fails, we remove the cache entry, so that the backend is queried again
      calculationResultCalculatedStore.update(
        deleteEntitiesByPredicate(({ id }) => id === resetPlanId)
      )
    })
  }

  public async getArticleListForPlanExport(planId: number): Promise<ResultPart[] | undefined> {
    let calculationResult = calculationResultStore.query(getEntity(planId))

    if (!calculationResult?.partList) {
      calculationResult = await this.getCalculationResultFromDao(planId, calculationResult)
    }

    return calculationResult?.partList
  }

  public async getArticleListWithCycleUsageForPlan(planId: number): Promise<PartList | undefined> {
    const articleList = await this.getArticleListForPlanExport(planId)

    if (!articleList) {
      return undefined
    } else {
      const partlist = articleList
      const plan = await this.planRepository.findOne(planId)
      if (!plan) {
        throw new Error('Plan not found')
      }
      let stock: Stock | undefined
      if (plan.stockId) {
        stock = await this.stockRepository.loadByIdWithArticles(plan.stockId)
      }

      return await CalculationResultService.calculateCycleUsage(partlist, stock)
    }
  }

  public async getResultMessages(planId: number): Promise<CalculationResultProtocol | null> {
    let calculationResult = calculationResultStore.query(getEntity(planId))
    if (calculationResult?.resultProtocol) {
      return calculationResult.resultProtocol
    } else {
      calculationResult = await this.getCalculationResultFromDao(planId, calculationResult)

      return calculationResult?.resultProtocol ?? null
    }
  }

  public async getResultXML(planId: number): Promise<string | null> {
    let calculationResult = calculationResultStore.query(getEntity(planId))
    if (calculationResult?.resultXML) {
      return calculationResult.resultXML
    } else {
      calculationResult = await this.getCalculationResultFromDao(planId, calculationResult)

      return calculationResult?.resultXML ?? null
    }
  }

  public async getResult(planId: number): Promise<CalculationResult | undefined> {
    let calculationResult = calculationResultStore.query(getEntity(planId))
    if (calculationResult?.resultXML) {
      return calculationResult
    } else {
      calculationResult = await this.getCalculationResultFromDao(planId, calculationResult)

      return calculationResult
    }
  }

  public async getIsCalculated(planId: number): Promise<boolean> {
    const calculationResultCalculated = calculationResultCalculatedStore.query(getEntity(planId))
    if (calculationResultCalculated !== undefined) {
      return calculationResultCalculated.calculated
    }

    const isCalculated = await this.calculationResultDao.getIsCalculated(planId)

    calculationResultCalculatedStore.update(
      upsertEntities({ calculated: isCalculated, id: planId })
    )

    return isCalculated
  }

  public async getResultImage(planId: number, thumbnail: boolean = false): Promise<string | null> {
    const calculationResult = calculationResultStore.query(getEntity(planId))
    const calculationResultCalculated = calculationResultCalculatedStore.query(getEntity(planId))

    let resultBase64Image: string | null = null
    if (calculationResult) {
      if (calculationResult.resultBase64Image && !thumbnail) {
        resultBase64Image = calculationResult.resultBase64Image
      } else {
        if (thumbnail) {
          const resultImage = await this.calculationResultDao.getCalculationResultThumbnail(planId)
          resultBase64Image = resultImage?.resultBase64Thumbnail ?? null
        } else {
          const resultImage = await this.calculationResultDao.getCalculationResultImage(planId)
          resultBase64Image = resultImage?.resultBase64Image ?? null
        }
      }
    } else if (
      calculationResultCalculated === undefined ||
      calculationResultCalculated.calculated === true
    ) {
      if (thumbnail) {
        const resultImage = await this.calculationResultDao.getCalculationResultThumbnail(planId)
        resultBase64Image = resultImage ? resultImage.resultBase64Thumbnail : null
      } else {
        const resultImage = await this.calculationResultDao.getCalculationResultImage(planId)
        resultBase64Image = resultImage ? resultImage.resultBase64Image : null
      }
    }

    // TODO Ideally we should be consistent in the database here, but there's no validation for it now
    if (resultBase64Image && !resultBase64Image.startsWith('data:image')) {
      resultBase64Image = `data:image/png;base64,${resultBase64Image}`
    }

    if (resultBase64Image) {
      this.setCalculationResultImageInStore(planId, resultBase64Image, thumbnail, calculationResult)
    }

    return resultBase64Image
  }

  private setCalculationResultImageInStore(
    planId: number,
    resultBase64Image: string,
    thumbnail: boolean,
    currentCalculationResult?: CalculationResult
  ): void {
    const calculationResult = currentCalculationResult ?? {
      planId,
    }

    if (thumbnail) {
      calculationResult.resultBase64Thumbnail = resultBase64Image
    } else {
      calculationResult.resultBase64Image = resultBase64Image
    }

    calculationResultStore.update(upsertEntities({ ...calculationResult, planId }))
    calculationResultCalculatedStore.update(upsertEntities({ calculated: true, id: planId }))
  }

  private async getCalculationResultFromDao(
    planId: number,
    storeCalculationResult?: CalculationResult
  ): Promise<CalculationResult | undefined> {
    const calculationResult = await this.calculationResultDao.getCalculationResult(planId)
    if (calculationResult && storeCalculationResult?.resultBase64Image) {
      calculationResult.resultBase64Image = storeCalculationResult.resultBase64Image
    }

    if (calculationResult) {
      calculationResultStore.update(upsertEntities({ ...calculationResult, planId }))
      calculationResultCalculatedStore.update(upsertEntities({ calculated: true, id: planId }))
    }

    return calculationResult
  }
}
