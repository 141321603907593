import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { NUMBER_VALIDATION_REGEX } from '../../../constants/regex'
import { ArticleForList } from '../article-list/article-list.component'
import { map, takeUntil } from 'rxjs'
import { IonInput } from '@ionic/angular'
import { CommonSettingsRepository } from '../../../repositories/common-settings.repository'

export enum ArticleItemPage {
  Stock = 'stock',
  ProductCatalog = 'product-catalog',
  Result = 'result',
  Blacklist = 'blacklist',
}

@Component({
  selector: 'efp-article-item',
  templateUrl: 'article-item.component.html',
  styleUrls: ['article-item.component.scss'],
})
export class ArticleItemComponent implements OnInit, OnDestroy {
  @Input() article!: ArticleForList
  @Input() articleForm?: FormGroup<{ [key: string]: FormControl<number> }>
  @Input() deletable = false
  @Input() articleItemPage!: ArticleItemPage
  @Input() selected = false
  @Input() disabledAndChecked?: boolean
  @Input() showArticleNumber = true
  @Input() showCalculation = false
  @Input() showCycleQuantity = false
  @Input() cycleNumber: number | null = null
  @Input() showArticleAmount = true
  @Input() disableEditing = false

  @Output() readonly deleteClicked = new EventEmitter<string>()
  @Output() readonly selectedChanged = new EventEmitter<string>()

  @ViewChild('articleAmount') articleAmountInput?: IonInput

  private destroy$ = new EventEmitter<void>()

  public numberValidationRegex = NUMBER_VALIDATION_REGEX
  public hasEnoughAmount = true
  public wasEdited = false
  public placeholderArticleAmount = 0
  public imgUrl$ = this.commonSettingsRepository.resourceUrl$.pipe(
    map((url) => `${url}/prod/Previews/${this.article.articleId}.jpg`)
  )

  constructor(private readonly commonSettingsRepository: CommonSettingsRepository) {}

  ngOnInit(): void {
    this.setHasEnoughAmount()
    this.placeholderArticleAmount = this.article.amount ?? 0

    const formControl = this.articleForm?.controls[this.article.articleId]

    if (this.disableEditing) {
      formControl?.disable()
    }

    if (formControl) {
      formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
        this.article.orderAmount = value
        this.setHasEnoughAmount()
      })
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next()
  }

  get editingDisabled(): boolean {
    return true
    return this.cycleNumber === null
  }

  public clickOnArticle(): void {
    if (this.showArticleAmount && this.articleAmountInput) {
      void this.articleAmountInput.setFocus()
    }
  }

  private setHasEnoughAmount(): void {
    if (this.article.difference && this.article.orderAmount !== undefined) {
      this.wasEdited = this.article.difference !== this.article.orderAmount
      this.hasEnoughAmount = this.article.difference <= this.article.orderAmount
    } else {
      this.hasEnoughAmount = true
    }
  }

  public toggle(): void {
    this.selectedChanged.emit(this.article.articleId)
  }

  public deleteArticle(articleId: string): void {
    this.deleteClicked.emit(articleId)
  }

  public increaseAmount(): void {
    const formControl = this.articleForm?.controls[this.article.articleId]
    if (formControl) {
      const currentValue = formControl.value || 0
      const newValue = Math.max(currentValue + 1, 0)
      formControl.setValue(newValue)
      this.article.orderAmount = newValue
      this.setHasEnoughAmount()
    }
  }

  public decreaseAmount(): void {
    const formControl = this.articleForm?.controls[this.article.articleId]
    if (formControl) {
      const currentValue = formControl.value || 0
      const newValue = Math.max(currentValue - 1, 0)
      formControl.setValue(newValue)
      this.article.orderAmount = newValue
      this.setHasEnoughAmount()
    }
  }
}
