/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseApiClient } from '../base-api-client';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CreatePlanForDoka360ProjectCommandParam } from '../models/create-plan-for-doka-360-project-command-param';
import { PlanListModel } from '../models/plan-list-model';
import { PlansModel } from '../models/plans-model';
import { UpdatePlanForDoka360ProjectCommandParam } from '../models/update-plan-for-doka-360-project-command-param';

@Injectable({ providedIn: 'root' })
export class Doka360ProjectsApiClient extends BaseApiClient {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPlansForDoka360ProjectUid()` */
  static readonly GetPlansForDoka360ProjectUidPath = '/api/v1/doka360projects/{doka360ProjectsUid}/plans';

  /**
   * Get plans for Doka360Project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlansForDoka360ProjectUid$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlansForDoka360ProjectUid$Plain$Response(
    params: {
      doka360ProjectsUid: string;
      limit?: number;
      offset?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PlanListModel>> {
    const rb = new RequestBuilder(this.rootUrl, Doka360ProjectsApiClient.GetPlansForDoka360ProjectUidPath, 'get');
    if (params) {
      rb.path('doka360ProjectsUid', params.doka360ProjectsUid, {});
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlanListModel>;
      })
    );
  }

  /**
   * Get plans for Doka360Project.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlansForDoka360ProjectUid$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlansForDoka360ProjectUid$Plain(
    params: {
      doka360ProjectsUid: string;
      limit?: number;
      offset?: number;
    },
    context?: HttpContext
  ): Observable<PlanListModel> {
    return this.getPlansForDoka360ProjectUid$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlanListModel>): PlanListModel => r.body)
    );
  }

  /**
   * Get plans for Doka360Project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlansForDoka360ProjectUid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlansForDoka360ProjectUid$Response(
    params: {
      doka360ProjectsUid: string;
      limit?: number;
      offset?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PlanListModel>> {
    const rb = new RequestBuilder(this.rootUrl, Doka360ProjectsApiClient.GetPlansForDoka360ProjectUidPath, 'get');
    if (params) {
      rb.path('doka360ProjectsUid', params.doka360ProjectsUid, {});
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlanListModel>;
      })
    );
  }

  /**
   * Get plans for Doka360Project.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlansForDoka360ProjectUid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlansForDoka360ProjectUid(
    params: {
      doka360ProjectsUid: string;
      limit?: number;
      offset?: number;
    },
    context?: HttpContext
  ): Observable<PlanListModel> {
    return this.getPlansForDoka360ProjectUid$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlanListModel>): PlanListModel => r.body)
    );
  }

  /** Path part for operation `createPlanForDoka360Project()` */
  static readonly CreatePlanForDoka360ProjectPath = '/api/v1/doka360projects/{doka360ProjectsUid}/plans';

  /**
   * Create plan for Doka360Project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPlanForDoka360Project$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createPlanForDoka360Project$Plain$Response(
    params: {
      doka360ProjectsUid: string;
      body: CreatePlanForDoka360ProjectCommandParam
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PlansModel>> {
    const rb = new RequestBuilder(this.rootUrl, Doka360ProjectsApiClient.CreatePlanForDoka360ProjectPath, 'post');
    if (params) {
      rb.path('doka360ProjectsUid', params.doka360ProjectsUid, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlansModel>;
      })
    );
  }

  /**
   * Create plan for Doka360Project.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPlanForDoka360Project$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createPlanForDoka360Project$Plain(
    params: {
      doka360ProjectsUid: string;
      body: CreatePlanForDoka360ProjectCommandParam
    },
    context?: HttpContext
  ): Observable<PlansModel> {
    return this.createPlanForDoka360Project$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlansModel>): PlansModel => r.body)
    );
  }

  /**
   * Create plan for Doka360Project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPlanForDoka360Project()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createPlanForDoka360Project$Response(
    params: {
      doka360ProjectsUid: string;
      body: CreatePlanForDoka360ProjectCommandParam
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PlansModel>> {
    const rb = new RequestBuilder(this.rootUrl, Doka360ProjectsApiClient.CreatePlanForDoka360ProjectPath, 'post');
    if (params) {
      rb.path('doka360ProjectsUid', params.doka360ProjectsUid, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlansModel>;
      })
    );
  }

  /**
   * Create plan for Doka360Project.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPlanForDoka360Project$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createPlanForDoka360Project(
    params: {
      doka360ProjectsUid: string;
      body: CreatePlanForDoka360ProjectCommandParam
    },
    context?: HttpContext
  ): Observable<PlansModel> {
    return this.createPlanForDoka360Project$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlansModel>): PlansModel => r.body)
    );
  }

  /** Path part for operation `updatePlanForDoka360Project()` */
  static readonly UpdatePlanForDoka360ProjectPath = '/api/v1/doka360projects/{doka360ProjectsUid}/plans/{engHubPlanUid}';

  /**
   * Update plan for Doka360Project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePlanForDoka360Project$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePlanForDoka360Project$Plain$Response(
    params: {
      doka360ProjectsUid: string;
      engHubPlanUid: string;
      body: UpdatePlanForDoka360ProjectCommandParam
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PlansModel>> {
    const rb = new RequestBuilder(this.rootUrl, Doka360ProjectsApiClient.UpdatePlanForDoka360ProjectPath, 'patch');
    if (params) {
      rb.path('doka360ProjectsUid', params.doka360ProjectsUid, {});
      rb.path('engHubPlanUid', params.engHubPlanUid, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlansModel>;
      })
    );
  }

  /**
   * Update plan for Doka360Project.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePlanForDoka360Project$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePlanForDoka360Project$Plain(
    params: {
      doka360ProjectsUid: string;
      engHubPlanUid: string;
      body: UpdatePlanForDoka360ProjectCommandParam
    },
    context?: HttpContext
  ): Observable<PlansModel> {
    return this.updatePlanForDoka360Project$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlansModel>): PlansModel => r.body)
    );
  }

  /**
   * Update plan for Doka360Project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePlanForDoka360Project()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePlanForDoka360Project$Response(
    params: {
      doka360ProjectsUid: string;
      engHubPlanUid: string;
      body: UpdatePlanForDoka360ProjectCommandParam
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PlansModel>> {
    const rb = new RequestBuilder(this.rootUrl, Doka360ProjectsApiClient.UpdatePlanForDoka360ProjectPath, 'patch');
    if (params) {
      rb.path('doka360ProjectsUid', params.doka360ProjectsUid, {});
      rb.path('engHubPlanUid', params.engHubPlanUid, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlansModel>;
      })
    );
  }

  /**
   * Update plan for Doka360Project.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePlanForDoka360Project$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePlanForDoka360Project(
    params: {
      doka360ProjectsUid: string;
      engHubPlanUid: string;
      body: UpdatePlanForDoka360ProjectCommandParam
    },
    context?: HttpContext
  ): Observable<PlansModel> {
    return this.updatePlanForDoka360Project$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlansModel>): PlansModel => r.body)
    );
  }

}
