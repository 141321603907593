import { Injectable } from '@angular/core'
import { LoadingSpinnerService } from './loading-spinner.service'
import { FileService } from './file.service'
import { ResultMessageService } from '../pages/result/services/result-message.service'
import { ActivatedRoute } from '@angular/router'
import { CalculationResultRepository } from '../repositories/calculation-result.repository'
import { AppBackgroundService } from './app-background.service'
import {
  ApplicationInsightsService,
  ApplicationInsightsStates,
} from './applicationInsights.service'
import { MeasurementLegendService } from './measurement-legend.service'
import { PlanSettingsService } from './plan-settings.service'
import { Screenshot } from '../models/screenshot'
import { ScreenshotService } from './screenshot.service'
import { PlanService } from './plan.service'
import { Translation } from './translation.service'
import { PdfWorkerRequest, PdfWorkerResponse } from '../worker/resultPdfWorkerData'
import { AlertController } from '@ionic/angular'
import { PlanSettings } from '../models/planSettings'
import { Plan } from '../models/plan'
import { Part } from '../models/part'

@Injectable({
  providedIn: 'root',
})
export class GeneratePDFService {
  constructor(
    private readonly loadingSpinner: LoadingSpinnerService,
    private readonly fileService: FileService,
    private readonly messageService: ResultMessageService,
    private readonly activeRoute: ActivatedRoute,
    private readonly calculationResultRepository: CalculationResultRepository,
    private readonly appBackgroundService: AppBackgroundService,
    private readonly appInsightsService: ApplicationInsightsService,
    private readonly measurementLegendService: MeasurementLegendService,
    private readonly planSettingsService: PlanSettingsService,
    private readonly screenshotService: ScreenshotService,
    private readonly planService: PlanService,
    private readonly translate: Translation,
    private readonly alertCtrl: AlertController
  ) {}

  public async generatePDF(
    plan: Plan,
    planSettings: PlanSettings,
    screens: Screenshot[],
    includeTotalStock: boolean,
    includeCycleStock: boolean,
    EFPWebLink: string
  ): Promise<PdfWorkerResponse | undefined> {
    let resultStock: undefined | Part[] = undefined
    if (includeTotalStock || includeCycleStock) {
      resultStock = (
        await this.calculationResultRepository.getArticleListWithCycleUsageForPlan(plan.id)
      )?.parts
    }

    const logMessages = this.messageService.messagesForPDF.map((message) =>
      this.translate.translate(message.message ? message.message : '')
    )

    const translations: PdfWorkerRequest['translations'] = {
      'PDF.AMOUNT': this.translate.translate('PDF.AMOUNT'),
      'PDF.ARTICLE_NR': this.translate.translate('PDF.ARTICLE_NR'),
      'PDF.CREATED_WITH': this.translate.translate('PDF.CREATED_WITH'),
      'PDF.DESCRIPTION': this.translate.translate('PDF.DESCRIPTION'),
      'PDF.ERROR_CALCULATION': this.translate.translate('PDF.ERROR_CALCULATION'),
      'PDF.PAGE': this.translate.translate('PDF.PAGE'),
      'PDF.TOTAL_AMOUNT': this.translate.translate('PDF.TOTAL_AMOUNT'),
      'VIEWER.CYCLE': this.translate.translate('VIEWER.CYCLE'),
      'ACC.PDF_HEADER': this.translate.translate('ACC.PDF_HEADER'),
      'ACC.PDF_MESSAGE': this.translate.translate('ACC.PDF_MESSAGE'),
    }

    if (plan && planSettings) {
      const pdfData: PdfWorkerRequest = {
        isPdfWorkerRequest: true,
        screens,
        logMessages,
        includeTotalStock,
        includeCycleStock,
        resultStock,
        translations,
        buildingType: plan.buildingType.toString(),
        measurementUnit: planSettings.measurementUnit,
        slabThicknes: this.measurementLegendService.convertValue(
          planSettings.measurementUnit,
          planSettings.slabThickness
        ),
        slabTopLevel: this.measurementLegendService.convertValue(
          planSettings.measurementUnit,
          planSettings.slabHeight
        ),
        wallHeight: this.measurementLegendService.convertValue(
          planSettings.measurementUnit,
          planSettings.wallHeight
        ),
        articleMeasurementUnit: this.measurementLegendService.getMeasurementUnitArticle(
          planSettings.formworkWall
        ),
        EFPWebLink,
      }
      this.appInsightsService.addUserEvent(ApplicationInsightsStates.PDF_CREATED, plan.id)

      return await this.appBackgroundService.generateResultPdfInBackground(pdfData)
    } else {
      return undefined
    }
  }
}
