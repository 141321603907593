import JSZip from 'jszip'
import {
  PROTOCOL_JSON_FILE_NAME,
  RESULT_JSON_FILE_NAME,
  RESULT_PNG_FILE_NAME,
  RESULT_THUMBNAIL_PNG_FILE_NAME,
  RESULT_XML_FILE_NAME,
} from '../constants/files'
import { Part, PartList, ResultPart } from '../models/part'
import { CalculationResult } from '../models/calculationResult'
import { CalculationResultProtocol } from '../models/calculationResultProtocol'
import { CalculationResultZip } from '../models/calculationResultZip'
import { Stock } from '../models/stock'

export class CalculationResultService {
  constructor() {}

  // Necessary because cycle numbers 2 & 3 are reserved by tipos
  private static adjustCycleNumber(cycleNumber: number): number {
    return cycleNumber >= 4 ? cycleNumber - 2 : cycleNumber
  }

  public static async calculateCycleUsage(
    partlist: ResultPart[],
    stock: Stock | undefined
  ): Promise<PartList | undefined> {
    /*
        Necessary, because the backend calculation returns wrong stock data!
        E.g. 100 stock, 6 demand and the returned stock is 6 and not 100
      */

    const cycleAmount = Math.max(
      ...partlist.map((part) =>
        Math.max(
          ...part.taktmengen.map((takt) =>
            CalculationResultService.adjustCycleNumber(takt.taktnummer)
          )
        )
      )
    )

    const usedCycleNumbers = new Set<number>() //
    let parts: Part[] = partlist.map((it) => {
      const cycleUsage = new Array(cycleAmount).fill(0)
      it.taktmengen.forEach((takt) => {
        const cycleNumber = CalculationResultService.adjustCycleNumber(takt.taktnummer)

        usedCycleNumbers.add(cycleNumber)
        cycleUsage[cycleNumber - 1] = takt.lieferant + takt.bauhof + takt.baustelle
      })
      /*
        Necessary, because the backend calculation returns wrong stock data!
        E.g. 100 stock, 6 demand and the returned stock is 6 and not 100
      */
      let stockValue: number | undefined
      if (stock !== undefined) {
        stockValue = stock.articles?.find(
          (article) => article.articleId === it.artikelnummer
        )?.amount
      }

      return {
        articleId: it.artikelnummer,
        name: it.bezeichnung,
        amount: it.stueckzahlLieferant,
        demand: it.montageStueckzahl,
        stock: stockValue ?? it.stueckzahlBauhof + it.stueckzahlBaustelle,
        cycleUsage,
        usedCycleNumbers,
      }
    })

    parts = parts.map((part) => {
      part.usedCycleNumbers = usedCycleNumbers
      return part
    })

    return {
      parts,
      usedCycleNumbers,
    }
  }

  public static async extractResult(zip: JSZip, planId: number): Promise<CalculationResult> {
    const result: CalculationResult = { planId }
    for (const filename in zip.files) {
      const file = zip.file(filename)
      if (file) {
        if (filename === RESULT_XML_FILE_NAME) {
          const fileContent = await file.async('string')
          const parser = new DOMParser()
          const resultXML = parser.parseFromString(fileContent, 'text/xml')
          // remove stock to save space
          resultXML.getElementsByTagName('initialStock')[0].innerHTML = ''
          result.resultXML = new XMLSerializer().serializeToString(resultXML)
        } else if (filename === PROTOCOL_JSON_FILE_NAME) {
          const fileContent = await file.async('string')
          result.resultProtocol = <CalculationResultProtocol>JSON.parse(fileContent)
        }
        // stock
        else if (filename === RESULT_JSON_FILE_NAME) {
          const fileContent = await file.async('string')
          const resultToParse = <CalculationResultZip>JSON.parse(fileContent)
          result.partList = resultToParse.partlist
        } else if (filename === RESULT_PNG_FILE_NAME) {
          result.resultBase64Image = await file.async('base64')
          if (result.resultBase64Image) {
            result.resultBase64Thumbnail = await this.createThumbnail(result.resultBase64Image)
          }
        }
        // only import
        else if (filename === RESULT_THUMBNAIL_PNG_FILE_NAME) {
          result.resultBase64Thumbnail = await file.async('base64')
        }
      }
    }
    return result
  }

  public static async createThumbnail(tiposImage: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const thumbnailMaxValue = 256
      const canvas = document.createElement('canvas')
      const context2D = canvas.getContext('2d')
      canvas.height = canvas.width = thumbnailMaxValue
      const image = new Image()

      image.onload = () => {
        const aspectRatio = Math.min(
          thumbnailMaxValue / image.width,
          thumbnailMaxValue / image.height
        )
        const newWidth = image.width * aspectRatio
        const newHeight = image.height * aspectRatio

        context2D?.drawImage(
          image,
          (thumbnailMaxValue - newWidth) / 2,
          (thumbnailMaxValue - newHeight) / 2,
          newWidth,
          newHeight
        )
        resolve(canvas.toDataURL('image/png').split(',')[1])
      }
      image.onerror = (e) => reject(e)

      image.src = 'data:image/png;base64,' + tiposImage
    })
  }
}
