import { Component, HostListener, NgZone, OnInit, ViewContainerRef } from '@angular/core'
import { Router } from '@angular/router'
import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { ModalController, Platform } from '@ionic/angular'
import { Subscription, combineLatest, firstValueFrom } from 'rxjs'
import { filter, throttleTime } from 'rxjs/operators'
import { register } from 'swiper/element/bundle'
import { environment } from '../environments/environment'
import { AuthenticationRepository } from './repositories/authentication-repository.service'
import {
  ApplicationInsightsService,
  ApplicationInsightsStates,
} from './services/applicationInsights.service'
import { AuthenticationService } from './services/authentication.service'
import { FileHandlerService } from './services/file-handler.service'
import { HttpRequestSyncService, SynchronizationState } from './services/http-request-sync.service'
import { LayoutService } from './services/layout.service'
import { PlanSettingsService } from './services/plan-settings.service'
import { Translation } from './services/translation.service'
import { HttpRequestCheckAppVersionService } from './services/http-request-check-app-version.service'
import { Location } from '@angular/common'
import { ScreenOrientation } from '@capacitor/screen-orientation'
import { ACCAuthenticationService } from './services/acc-auth.service'
import { EventRepository } from './repositories/event.repository'
import { CacheService } from './services/cache/cache.service'
import { Network } from '@capacitor/network'
import { WidgetService } from './services/widget.service'
import { PrivacyPolicyModalComponent } from './shared/components/privacy-policy-modal/privacy-policy-modal.component'
import { AppSettingsRepository } from './repositories/app-settings.repository'
import { PrivacyPolicyRepository } from './repositories/privacy-policies.repository'
import { DevicePreferencesService } from './services/device-preferences.service'
import Hotjar from '@hotjar/browser'
import { DokaCommonService, ModalStyle } from '@doka-shared/common'

register()

@Component({
  selector: 'efp-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public isSynchronizationError = false
  public isAppVersionError = false
  public isInvalidCacheError = false

  private isPrivacyPolicyPresented = false

  public hasNetworkConnection = false

  private tokenSub?: Subscription
  private synchronizationState = SynchronizationState.SYNC_SAVED

  public modalStyleEnum = ModalStyle

  private presentedModal: HTMLIonModalElement | null = null

  constructor(
    private readonly translate: Translation,
    private readonly fileHandlerService: FileHandlerService,
    private readonly authService: AuthenticationService,
    private readonly authRepository: AuthenticationRepository,
    private readonly appInsightsService: ApplicationInsightsService,
    private readonly platform: Platform,
    private readonly planSettingsService: PlanSettingsService,
    private readonly router: Router,
    private readonly location: Location,
    private readonly httpRequestSyncService: HttpRequestSyncService,
    private readonly layoutService: LayoutService,
    private readonly httpRequestCheckAppVersionService: HttpRequestCheckAppVersionService,
    private readonly accAuthService: ACCAuthenticationService,
    private readonly eventRepository: EventRepository, // needed to initialize the event repository
    private readonly cacheService: CacheService,
    private readonly widgetService: WidgetService,
    private readonly zone: NgZone,
    private readonly modalCtrl: ModalController,
    private readonly appSettingsRepository: AppSettingsRepository,
    private readonly privacyPolicyRepository: PrivacyPolicyRepository,
    private readonly devicePreferencesService: DevicePreferencesService,
    private readonly dokaCommonService: DokaCommonService,
    private readonly viewContainerRef: ViewContainerRef
  ) {
    if (Capacitor.isPluginAvailable('App')) {
      void App.addListener('appUrlOpen', (urlOpen) => {
        void (async () => {
          this.fileHandlerService.registerFile(urlOpen.url)
          const hasConfirmedAGBs = await firstValueFrom(
            this.privacyPolicyRepository.isPrivacyPolicyAccepted$
          )

          if (hasConfirmedAGBs) {
            await this.fileHandlerService.handleFile()
          }
        })()
      })

      this.platform.backButton.subscribe(() => {
        const url = this.router.url
        if (url.indexOf('/homepage') !== -1 || url.indexOf('/login') !== -1) {
          void App.exitApp().then()
        } else {
          this.location.back()
        }
      })

      // manual refresh of token because the automatic token refresh of the library doesn´t seem to work after the app is paused
      this.tokenSub?.unsubscribe()
      this.tokenSub = this.platform.resume.subscribe(() => {
        void this.authService.refreshToken()
      })
    }

    if (Capacitor.isNativePlatform()) {
      const portraitOnly = this.layoutService.getPortraitOnlySize()
      if (portraitOnly) {
        void ScreenOrientation.lock({ orientation: 'portrait' })
      } else {
        void ScreenOrientation.unlock()
      }
    } else {
      // 3909969 = company id, 6 = hotjar version
      Hotjar.init(3909969, 6)
    }
  }

  async ngOnInit(): Promise<void> {
    this.authRepository.authenticated$.subscribe((loggedIn) => {
      if (loggedIn) {
        void this.refreshDataOnLogin()
      }
    })

    this.httpRequestSyncService.synchronizationState$.subscribe((value) => {
      this.synchronizationState = value
    })

    this.hasNetworkConnection = (await Network.getStatus()).connected
    void Network.addListener('networkStatusChange', (status) => {
      this.zone.run(() => {
        this.hasNetworkConnection = status.connected
      })
    })

    combineLatest([
      this.authRepository.authenticated$,
      this.privacyPolicyRepository.isPrivacyPolicyAccepted$,
    ]).subscribe(([loggedIn, isPrivacyPolicyAccepted]) => {
      if (loggedIn && isPrivacyPolicyAccepted === false) {
        void this.presentPrivacyPolicyModal()
      }
    })

    this.initRequestErrorListener()
    await this.initApplication()
  }

  private async presentPrivacyPolicyModal(): Promise<void> {
    if (this.isPrivacyPolicyPresented) {
      return
    }
    this.isPrivacyPolicyPresented = true
    const shouldShowCloudSaveAcceptance =
      Capacitor.isNativePlatform() &&
      !(await this.devicePreferencesService.fetchCloudSaveAccepted())
    this.presentedModal = await this.modalCtrl.create({
      component: PrivacyPolicyModalComponent,
      backdropDismiss: false,
      cssClass: 'soft-edges-modal',
      componentProps: {
        shouldShowCloudSaveAcceptance,
      },
    })
    await this.presentedModal.present()
    await this.presentedModal.onDidDismiss()
    this.presentedModal = null
    this.isPrivacyPolicyPresented = false
  }

  public closeErrorModal(): void {
    window.location.reload()
  }

  private initRequestErrorListener(): void {
    this.httpRequestSyncService.synchronizationState$
      .pipe(
        filter((x) => x === SynchronizationState.SYNC_FAILED),
        throttleTime(5000)
      )
      .subscribe(() => {
        this.isSynchronizationError = true
      })

    this.httpRequestCheckAppVersionService.appVersionError$.subscribe(() => {
      this.isAppVersionError = true
    })

    this.cacheService.invalidLocalCacheError$.subscribe(() => {
      if (!this.widgetService.isOnWidgetHostname && !this.widgetService.isOnWidgetPath) {
        this.isInvalidCacheError = true
      }
    })
  }

  private async initApplication(): Promise<void> {
    // Set the language
    await this.translate.initTranslation(this.authService.isOAuthLoggedIn)
    await this.authService.configureOauth2()
    const hasConfirmedAGBs = await firstValueFrom(
      this.privacyPolicyRepository.isPrivacyPolicyAccepted$
    )
    environment.applicationReady = true

    // Init auth or open agbs
    if (hasConfirmedAGBs) {
      await this.fileHandlerService.handleFile()
    }

    this.dokaCommonService.init(this.viewContainerRef)
  }

  @HostListener('window:beforeunload', ['$event'])
  showAlertMessageWhenClosingTabDuringSync(): boolean {
    if (this.synchronizationState === SynchronizationState.SYNC_RUNNING) {
      return false
    }
    return true
  }

  private async refreshDataOnLogin(): Promise<void> {
    await this.translate.updateLanguageFromAppSettings()
    await this.planSettingsService.setFirstSupportedFormworkSystemForAppPlanSettingsIfNecessary()
    await this.accAuthService.initAccAuth()

    this.appInsightsService.enableApplicationInsights(this.authService.getAccessToken() ?? '')
    this.appInsightsService.addUserEvent(
      (await this.authService.isDokaUser())
        ? ApplicationInsightsStates.INTERNAL_USER
        : ApplicationInsightsStates.EXTERNAL_USER
    )
  }

  public getModalTitle(): string {
    if (this.isAppVersionError) {
      return this.translate.translate('VERSIONCHECK.FAILED')
    } else if (this.isSynchronizationError) {
      return this.hasNetworkConnection
        ? this.translate.translate('SYNC.FAILED')
        : this.translate.translate('SYNC.FAILED_NO_INTERNET')
    } else if (this.isInvalidCacheError) {
      return this.translate.translate('CACHE.OUTDATED_LOCAL')
    }
    return ''
  }

  public getModalText(): string {
    if (this.isAppVersionError) {
      return this.translate.translate('VERSIONCHECK.FAILED_DESCRIPTION')
    } else if (this.isSynchronizationError) {
      return this.translate.translate('SYNC.FAILED_DESCRIPTION')
    } else if (this.isInvalidCacheError) {
      return this.translate.translate('CACHE.OUTDATED_LOCAL_DESCRIPTION')
    }
    return ''
  }

  public getModalConfirmBtnText(): string {
    if (Capacitor.isNativePlatform() && this.isAppVersionError) {
      return this.translate.translate('GENERAL.OK')
    }
    if (this.isSynchronizationError || this.isInvalidCacheError) {
      return this.translate.translate('SYNC.RELOAD')
    }
    return ''
  }

  public openAppStore(): void {
    if (Capacitor.getPlatform() === 'ios') {
      window.open('https://apps.apple.com/app/id1454210778')
    } else if (Capacitor.getPlatform() === 'android') {
      window.open('https://play.google.com/store/apps/details?id=com.doka.efp')
    }
  }

  public getModalConfirmFunction(): void {
    if (this.isAppVersionError) {
      this.openAppStore()
    } else {
      this.closeErrorModal()
    }
  }

  public getModalStyle(): ModalStyle {
    return ModalStyle.Alert
  }

  public getModalDismissable(): boolean {
    return false
  }
}
