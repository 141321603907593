<div class="mobile-container">
  <div class="pt-3 d-flex">
    <ion-label class="sub-title">{{
      'FAVOURITES.PROFILE.LIST_SOLUTIONS_HEADING' | translate
    }}</ion-label>
  </div>

  <p class="header-description">
    <ion-note>
      {{ 'FAVOURITES.PLANSETTINGS.FAVOURITES_CALC_HINT' | translate }}
    </ion-note>
  </p>
</div>

<div *ngIf="this.selectedTypes" class="list">
  <ng-container *ngFor="let type of this.selectedTypes">
    <efp-list-tile-styling>
      <div class="gap-2 d-flex-col">
        <div class="mobile-container">
          <ion-label class="label">
            {{ type.ID | favTranslate }}
          </ion-label>
        </div>
        <div class="mobile-container full-width">
          <ion-item lines="none" button="true" (click)="navToFavouriteDetail(type.ID)">
            <div class="flex-1 gap-2 d-flex">
              <efp-thumbnail-action-item
                class="basis-56"
                [title]="type.ID | favTranslate"
                [imgSrc]="this.imgUrl(type.Loesung[0].ID)"></efp-thumbnail-action-item>
              <div class="flex-6 d-flex-col title-subtitle-container" color="primary">
                <div class="primary-title">
                  {{ type.Loesung[0].ID | favTranslate }}
                </div>
                <efp-badge [isActive]="true">
                  {{ 'FAVOURITES.PROFILE.SOLUTIONS.RANK_FAVOURED' | translate }}
                </efp-badge>
              </div>
              <div class="icon-container">
                <span
                  class="action-icon-large action-icon bg-icon-transparent"
                  *ngIf="!readOnlyMode; else readOnly">
                  edit
                </span>
                <ng-template #readOnly>
                  <span class="action-icon-large action-icon bg-icon-transparent">visibility</span>
                </ng-template>
              </div>
            </div>
          </ion-item>
        </div>
      </div>
    </efp-list-tile-styling>
  </ng-container>
  <div class="pb-3 d-flex link-container">
    <button class="button button-secondary" (click)="navToFavouriteDetail()">
      {{ 'FAVOURITES.PROFILE.SOLUTIONS.SHOW_ALL' | translate }}
      <span class="button-icon">chevron_right</span>
    </button>
  </div>
</div>
