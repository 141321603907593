import { Injectable } from '@angular/core'
import { CalculationResultsApiClient } from '@efp/api'
import { firstValueFrom } from 'rxjs'
import { CalculationResultResetCalculationDao } from '../reset-calculation-result.dao'

@Injectable()
export class ResetCalculationResultHttpDao extends CalculationResultResetCalculationDao {
  constructor(private readonly calculationResultApiClient: CalculationResultsApiClient) {
    super()
  }

  /**
   * Remove plan results, updates the current plan navigation step and remove changed result parts & screenshots.
   */
  public async resetCalculation(planId: number): Promise<void> {
    await firstValueFrom(this.calculationResultApiClient.deleteCalculationResult({ planId }))
  }
}
