import { Injectable } from '@angular/core'
import {
  CalculationResultsApiClient,
  CalculationResultImageModel,
  CalculationResultThumbnailModel,
} from '@efp/api'
import {
  CalculationResultDao,
  mapCalculationResultModelToCalculationResult,
  mapCalculationResultToCreateCalculationResultCommandParam,
} from '../calculation-result.dao'
import { firstValueFrom } from 'rxjs'
import { CalculationResult } from '../../../models/calculationResult'

@Injectable()
export class CalculationResultHttpDao extends CalculationResultDao {
  constructor(private readonly calculationResultApiClient: CalculationResultsApiClient) {
    super()
  }

  public async saveCalculationResult(
    result: Omit<CalculationResult, 'planId'>,
    planId: number
  ): Promise<void> {
    if (!result || !planId) {
      throw new Error('Provided plan id or result ZIP are empty')
    }

    await firstValueFrom(
      this.calculationResultApiClient.createCalculationResult({
        planId,
        body: mapCalculationResultToCreateCalculationResultCommandParam({
          ...result,
          planId,
        }),
      })
    )
  }

  public async getCalculationResult(planId: number): Promise<CalculationResult | undefined> {
    const calculationResult = await firstValueFrom(
      this.calculationResultApiClient.getCalculationResult({ planId })
    )
    return calculationResult
      ? mapCalculationResultModelToCalculationResult(calculationResult)
      : undefined
  }

  public async getCalculationResultImage(
    planId: number
  ): Promise<CalculationResultImageModel | undefined> {
    return firstValueFrom(this.calculationResultApiClient.getCalculationResultImage({ planId }))
  }

  public async getCalculationResultThumbnail(
    planId: number
  ): Promise<CalculationResultThumbnailModel | undefined> {
    return firstValueFrom(this.calculationResultApiClient.getCalculationResultThumbnail({ planId }))
  }

  public async getIsCalculated(planId: number): Promise<boolean> {
    return firstValueFrom(
      this.calculationResultApiClient.getCalculationResultCalculated({ planId })
    )
  }

  /**
   * Remove plan results, updates the current plan navigation step and remove changed result parts & screenshots.
   */
  public async resetCalculation(planId: number): Promise<void> {
    await firstValueFrom(this.calculationResultApiClient.deleteCalculationResult({ planId }))
  }

  // only for native migrations from calculationResult
  public async saveCalculationResultFromFolder(): Promise<void> {
    return
  }
}
