<efp-list-header *ngIf="withProfileTitle">
  <div class="d-flex gap-3 title-container pb-2.5 pt-2.5">
    <ion-label>{{ 'FAVOURITES.PROFILE.FAVORITE_PROFILE' | translate }}</ion-label>
    <div class="title-divider"><span></span></div>
    <ion-label *ngIf="favouriteProfile" class="profile-title">{{
      (!(favouriteProfile.isStandard || favouriteProfile.isDoka360)
        ? favouriteProfile.name
        : 'FORMWORK.' + favouriteProfile.formworkSystemId
      ) | translate
    }}</ion-label>
  </div>
</efp-list-header>

<efp-list-header *ngIf="!withProfileTitle">
  <ion-label>{{ 'FAVOURITES.PROFILE.LIST_EXCLUSION_HEADING' | translate }}</ion-label>
</efp-list-header>
<div class="form-container">
  <div *ngIf="withProfileTitle" class="pt-3 d-flex">
    <ion-label class="sub-title">{{
      'FAVOURITES.PROFILE.LIST_EXCLUSION_HEADING' | translate
    }}</ion-label>
  </div>
  <div class="d-flex-col" [ngClass]="{ 'pb-4': bottomPadding }">
    <ion-item class="item-container" lines="none" button="true" [disabled]="readOnlyMode">
      <ion-toggle
        justify="space-between"
        class="fix-padding-right"
        [(ngModel)]="favouriteProfile.useOnlyRentableArticles"
        (ngModelChange)="changedUseOnlyRentableArticles()">
        {{ 'FAVOURITES.PROFILE.ONLY_RENTABLE_ARTICLES' | translate }}</ion-toggle
      >
    </ion-item>

    <ion-item
      class="item-container"
      lines="none"
      button="true"
      (click)="navToBlacklist()"
      [disabled]="readOnlyMode">
      <div class="flex-1 gap-2 d-flex">
        <ion-label class="flex-1 label center-vert">
          {{ 'FAVOURITES.PROFILE.ARTICLE_BLACKLIST' | translate }}
        </ion-label>
        <div *ngIf="articleCount !== undefined" class="right-container">
          <ion-label *ngIf="articleCount == 1" class="article-count-label center-vert">
            {{ articleCount }} {{ 'FAVOURITES.PROFILE.ARTICLE_EXCLUDED_SUFFIX_SINGLE' | translate }}
          </ion-label>
          <ion-label *ngIf="articleCount > 1" class="article-count-label center-vert">
            {{ articleCount }}
            {{ 'FAVOURITES.PROFILE.ARTICLE_EXCLUDED_SUFFIX_MULTIPLE' | translate }}
          </ion-label>
          <ion-icon src="/assets/icon/ic_edit.svg" color="primary"> </ion-icon>
        </div>
      </div>
    </ion-item>
  </div>
</div>
