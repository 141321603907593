import { Component, Input, OnInit } from '@angular/core'
import { PlanSettings } from '../../../models/planSettings'
import { FavouriteRepository } from '../../../repositories/favourite.repository'
import { FavouriteProfile, FavouriteSystem, FavouriteType } from '../../../models/favourites'
import {
  FavouriteSystemTypesList,
  favouritesSystemTypeList,
} from '../../../constants/favouritesSystemTypeList'
import { FavouriteTypeService } from '../../../pages/favourites/services/favourite-type.service'
import { ActivatedRoute, Router } from '@angular/router'
import { Subject, takeUntil } from 'rxjs'
import { PlanSettingsService } from '../../../services/plan-settings.service'
import { FavouritesService } from '../../../services/favourites.service'
import { CommonSettingsRepository } from '../../../repositories/common-settings.repository'

@Component({
  selector: 'efp-formwork-selected-settings',
  templateUrl: './formwork-selected-settings.component.html',
  styleUrls: ['./formwork-selected-settings.component.scss'],
})
export class FormworkSelectedSettingsComponent implements OnInit {
  @Input() planId?: number
  @Input() readOnlyMode = false

  public favouriteProfile?: FavouriteProfile
  public currentSystem?: FavouriteSystem
  public primaryFavouriteSystemTypeKeys: string[] = []
  public selectedTypes?: FavouriteType[]

  private favouriteSystemTypeList: FavouriteSystemTypesList[] = favouritesSystemTypeList

  constructor(
    private readonly favouritesRepo: FavouriteRepository,
    private readonly favouriteTypesService: FavouriteTypeService,
    private readonly router: Router,
    private readonly planSettingsService: PlanSettingsService,
    private readonly route: ActivatedRoute,
    private readonly favoritesService: FavouritesService,
    private readonly commonSettingsRepository: CommonSettingsRepository
  ) {}

  private readonly pageLeave$ = new Subject<void>()

  ngOnInit(): void {
    this.planSettingsService.planSettingsChanged
      .pipe(takeUntil(this.pageLeave$))
      .subscribe((planSettings: PlanSettings) => {
        const favId = planSettings?.slabFavId ? planSettings.slabFavId : planSettings.wallFavId
        if (favId) void this.initFavouriteProfile(favId)
      })

    void this.favoritesService.initTranslation()
  }

  private async initFavouriteProfile(favId: number): Promise<void> {
    this.favouriteProfile = await this.favouritesRepo.findOneById(favId)

    if (this.favouriteProfile) {
      this.currentSystem = this.favouriteProfile.values.Schalungsstile.Schalsystem.filter(
        (x) => x.ID === this.favouriteProfile?.formworkSystemId
      )[0]
    }

    if (this.currentSystem) {
      const primaryIDs = this.favouriteSystemTypeList.filter(
        (x) => x.id === this.currentSystem?.ID
      )[0].primaryList
      const systemTypeIDs = this.currentSystem.Schalungsthema.map(
        (schalungsThema) => schalungsThema.ID
      )

      const primaryFavouriteSystemTypeKeys = systemTypeIDs.filter((primaryTypeID) => {
        return primaryIDs.includes(primaryTypeID)
      })

      if (primaryFavouriteSystemTypeKeys) {
        this.getSelectedSolutions(primaryFavouriteSystemTypeKeys)
      }
    }
  }

  private getSelectedSolutions(primaryFavouriteSystemTypeKeys: string[]): void {
    const currentSystem: FavouriteType[] = []
    primaryFavouriteSystemTypeKeys.forEach((key) => {
      if (this.favouriteProfile) {
        const currentType = this.favouriteTypesService.findFavoriteType(this.favouriteProfile, key)
        if (currentType) currentSystem?.push(currentType)
      }
    })
    this.selectedTypes = currentSystem
  }

  public async navToFavouriteDetail(itemId?: string): Promise<void> {
    //quick fix: cut off url params, they aren't needed and cause conflicts, resolve with #85208 cleanup app navigation
    const currentRoute = this.router.url.split('?')[0]
    if (itemId) {
      await this.router.navigate(
        [currentRoute, 'favourites', this.favouriteProfile?.id, 'solution-types', itemId],
        {
          relativeTo: this.route.parent,
        }
      )
    } else {
      await this.router.navigate([currentRoute, 'favourites', this.favouriteProfile?.id], {
        relativeTo: this.route.parent,
      })
    }
  }

  public imgUrl(key: string): string | undefined {
    return this.commonSettingsRepository.getFavImgUrl(key)
  }
}
